/**
 * @module LinkRow
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import '../Footer.scss';

/**
 * Represents HTML markup for a row of link elements.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onLinkClick - Handler function for link click event.
 * @param {object} props.row - Object of link row data.
 *
 * @returns {React.ReactElement} The LinkRow component.
 */
function LinkRow({ onLinkClick, row }) {
  if (!row || !row['@nodes']) {
    return null;
  }
  return (
    <ul className="page-nav-links-row">
      {row['@nodes'].map((item, i) => {
        const pageItem = row[item];
        return (
          <li className="page-link" key={`${pageItem['@id']}-${i}`}>
            <a href={pageItem.url} onClick={onLinkClick}>
              {pageItem.linkTitle}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default LinkRow;
