/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HubPreview
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { fetchHubPreview } from '../../helpers/dataFetchers/findsFetchers';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import HubPreviewFeaturedContent from './HubPreviewFeaturedContent';
import HubPreviewMainContent from './HubPreviewMainContent';
import useTheme from '../../hooks/useTheme';
import './HubPreview.scss';

const HubPreview = ({
  articleList,
  background,
  featuredArticle,
  link,
  linkLabel,
  preload,
  theme,
  title,
}) => {
  const { user } = useAuth();
  const { pageTheme } = useTheme();
  const [data, setData] = React.useState(preload);

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {object} params - The function params object.
   * @param {Event} params.component_url - Optional override of component url.
   * @param {Event} params.event - The Event object associated with the analytics call.
   * @param {Event} [params.label] - Optional label value.
   */
  function callAnalytics({ component_url, event, label }) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Hub Preview',
        component_url:
          component_url || event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  React.useEffect(() => {
    async function fetchData() {
      if (!preload) {
        setData(await fetchHubPreview({ articleList, featuredArticle }));
      } else {
        setData(preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuredArticle, articleList]);

  const getTheme = () => {
    if (background) {
      if (background === 'cream') {
        return 'light-mode';
      }
      return 'dark-mode';
    }
    const appliedTheme = getAppliedTheme(pageTheme, theme);
    return appliedTheme;
  };

  /**
   * Handler function for wrapper click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleWrapperClick(event) {
    callAnalytics({
      component_url:
        event.target.tagName.toLowerCase() !== 'a' && data?.featured
          ? data.featured['@name']
          : null,
      event,
    });
    if (event.target.tagName.toLowerCase() !== 'a' && data?.featured) {
      window.location.href = data.featured['@name'];
    }
  }

  /**
   * Handler function for link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    callAnalytics({ event });
  }

  return (
    <div
      className={`image-with-subtitles-wrapper container ${getTheme()} bg-${
        background || 'none'
      }`}
      data-testid="lc-hub-preview"
    >
      {data?.featured ? (
        <HubPreviewFeaturedContent
          featuredData={data.featured}
          handleLinkClick={handleLinkClick}
          handleWrapperClick={handleWrapperClick}
        />
      ) : null}

      <HubPreviewMainContent
        articlesList={data?.list}
        handleArrowClick={(event) => {
          callAnalytics({
            component_url: link,
            event,
            label: linkLabel,
          });
        }}
        handleLinkClick={handleLinkClick}
        link={link}
        linkLabel={linkLabel}
        theme={theme}
        title={title}
      />
    </div>
  );
};

export default HubPreview;
