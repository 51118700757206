/**
 * @module Heading
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './Heading.scss';

const Heading = (props) => {
  const {
    headingText,
    headingLevel,
    headingType,
    sbOnMobile,
    sbOnTabletAndUp,
    alignment = '',
    colorsetting,
    sectionId,
  } = props;
  const { isMobile } = useWindowSize();
  const { pageTheme } = useTheme();
  const Tag = `${headingLevel}`;
  const appliedTheme = getAppliedTheme(pageTheme, colorsetting)
    ?.replace('dark-mode', 'text-white')
    ?.replace('light-mode', 'text-black');
  const deviceSpecificClassName = isMobile ? sbOnMobile : sbOnTabletAndUp;

  return (
    <Tag
      className={`heading text-${headingType} ${colorsetting} container ${alignment} ${appliedTheme} ${deviceSpecificClassName} rich-text`}
      dangerouslySetInnerHTML={{ __html: headingText }}
      id={sectionId}
    ></Tag>
  );
};

export default Heading;
