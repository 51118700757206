/**
 * @module EmbedItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { combineQueryParamsAndUrl } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';

/**
 * Represents and iframe with customizable properties.
 *
 * @param {object} props - The component props object.
 * @param {string} props.id - The unique identifier for the iframe.
 * @param {boolean} props.isMobile - Boolean flag indicating whether or not the current screen is a mobile one.
 * @param {string} props.sectionId - The identifier for the section containing the iframe.
 * @param {object} props.type - The object containing the iframe properties.
 *
 * @returns {React.ReactElement} The IFrame component.
 */
function IFrame({ id, isMobile, sectionId, type }) {
  // Default iframe id to already-specified value or timestamped value, but
  // overriding if there is a section id value, using it with `iframe-` prefix.
  let iframeId = !id ? `iframe-${uuidv4()}` : id;
  const iframeRef = React.useRef(null);

  if (sectionId) {
    iframeId = `iframe-${sectionId}`;
  }

  const resizeIframeHeight = (event) => {
    if (!event.origin.match('life.church')) {
      return;
    }

    const message = event.data;
    const iFrame = document.getElementById(iframeId);

    // IMPORTANT: Ensure that the iframe exists before assuming and targeting it
    // for getting and setting its attributes.
    if (iFrame) {
      const iFrameSrc = new URL(iFrame.src);

      // Note: Only want to change height for Rock forms, not any/all other
      // embeddable forms on the page (e.g. a podcast iframe).
      const lcRegExp = /life.church/g;
      if (message?.height && lcRegExp.exec(iFrameSrc.host)) {
        iFrame.height = message.height;
        iFrame.classList.remove('iframe-100vh');
        iFrame.setAttribute('scrolling', 'no');
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', resizeIframeHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSetHeight = () => {
    return isMobile ? !!type.heightMobile : !!type.heightDesktop;
  };

  const getHeight = () => {
    return isMobile ? `${type.heightMobile}px` : `${type.heightDesktop}px`;
  };

  const getIframeSourceData = (url) => {
    try {
      const parsedUrl = new URL(url);
      const searchParamsSize = [...parsedUrl.searchParams.entries()].length;
      const isValid = Boolean(parsedUrl.toString());
      return { isValid, searchParamsSize };
    } catch (error) {
      return { isValid: false, searchParamsSize: 0 };
    }
  };

  let iframeSrc = '';
  const sourceUrl = type?.iframeSource;
  const { isValid, searchParamsSize } = getIframeSourceData(sourceUrl);

  if (isValid) {
    // IMPORTANT: The source url for the iframe will be updated only if it does not have search parameters.
    iframeSrc = searchParamsSize
      ? sourceUrl
      : combineQueryParamsAndUrl({
          baseUrl: sourceUrl,
          queryParams: encodeURI(type?.urlParams),
        });
  }

  return iframeSrc ? (
    <iframe
      className={
        !hasSetHeight() && !type.isSearchForm
          ? 'iframe-100vh embedded-iframe'
          : 'embedded-iframe'
      }
      data-gtm-yt-inspected-8867600_189="true"
      data-testid="lc-iframe"
      frameBorder="0"
      height={hasSetHeight() ? getHeight() : undefined}
      id={iframeId}
      ref={iframeRef}
      scrolling="yes"
      src={combineQueryParamsAndUrl({
        baseUrl: iframeSrc,
        queryParams: 'type=type-finds&theme=theme-dark',
      })}
      title="Embedded iframe"
      width="100%"
    >
      {' '}
    </iframe>
  ) : null;
}

export default IFrame;
