/**
 * @module ThemeContext
 */
import React from 'react';
import { COLOR_THEMES } from '../helpers/constants';

const ThemeContext = React.createContext({
  pageTheme: null,
  setPageTheme: null,
  themes: COLOR_THEMES,
});

/**
 * React Context Provider that provides the theme to its children components.
 *
 * @param {object} props - The context component props object.
 * @param {React.ReactNode} props.children - The React children around which the context provider is wrapped.
 *
 * @returns {React.ReactElement} The Theme Context Provider.
 */
function ThemeProvider({ children }) {
  const [pageTheme, setPageTheme] = React.useState('');

  const storePageTheme = React.useCallback((theme) => {
    if (Object.values(COLOR_THEMES).includes(theme)) {
      setPageTheme(theme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({
      pageTheme,
      storePageTheme,
      themes: COLOR_THEMES,
    }),
    [pageTheme, storePageTheme],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
