/**
 * @module FilterSelect
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { useSortBy } from 'react-instantsearch';
import ChevronUp from './ChevronUp';
import { ACTIONS, COLOR_THEMES, EVENTS } from '../../helpers/constants';
import './FilterSelect.scss';

/**
 * Convenience function to trigger callSegmentTrack.
 *
 * @param {object} params - The function params object.
 * @param {string} [params.action] - Optional value for action.
 * @param {string} [params.event] - Optional event associated with the tracking request.
 * @param {string} [params.label] - Optional value for label.
 * @param {object} [params.user] - Optional object containing the user data.
 */
function callAnalytics({ action, event, label, user }) {
  callSegmentTrack({
    event: event || EVENTS.buttonAction,
    properties: {
      action: action || ACTIONS.clicked,
      component: 'Filter Select',
      component_url: null,
      label,
      logged_in: !!user,
      preferred_campus: null,
      referrer: document?.referrer || null,
      title: document?.title || '',
      url: window?.location?.href,
      user_id: user?.['https://www.life.church/rock_person_alias_id'],
    },
  });
}

/**
 * Represents a component that displays a dropdown filter menu.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - The additional CSS class name for custom styling.
 * @param {Array<SortByItem>} props.items - Array of sorting options.
 * @param {string} props.theme - The current theme of the application.
 *
 * @returns {React.ReactElement} - The FilterSelect component.
 */
function FilterSelect({ className, items, theme }) {
  const { currentRefinement, options, refine } = useSortBy({ items });
  const { user } = useAuth();

  const themeClassName = theme === COLOR_THEMES['dark-mode'] ? 'dark' : 'light';
  const customClassName = className || '';

  /**
   * Handler function to handle select on-change.
   *
   * @param {Event} event - The Event object associated with the on-change.
   */
  function handleSelectChange(event) {
    refine(event.target.value);
    callAnalytics({ label: event.target.value, user });
  }

  return (
    <div
      className={`filter-select-wrapper ${themeClassName} ${customClassName}`.trim()}
    >
      <select
        className="filter-select"
        onChange={handleSelectChange}
        value={currentRefinement}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronUp />
    </div>
  );
}

export default FilterSelect;
