/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HubPreviewMainContent
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ArrowButton from '../ArrowButton/ArrowButton';
import ArticleItem from './ArticleItem';

/**
 * Represents the main section of the HubPreview component.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.articlesList - An array containing a list of article data objects.
 * @param {Function} props.handleArrowClick - Handler function for the arrow button click event.
 * @param {Function} props.handleLinkClick - Handler function for the section link click event.
 * @param {string} props.link - The section link URL.
 * @param {string} props.linkLabel - The section link label.
 * @param {string} props.theme - The theme for the component.
 * @param {string} props.title - The section title.
 *
 * @returns {React.ReactElement} - The HubPreviewMainContent component.
 */
function HubPreviewMainContent({
  articlesList,
  handleArrowClick,
  handleLinkClick,
  link,
  linkLabel,
  theme,
  title,
}) {
  return (
    <div className="main-content" data-testid="lc-hub-preview-main-content">
      <div className="flex-content">
        {title ? <h2 className="title">{title}</h2> : null}
        {link ? (
          <div className="link-content">
            {linkLabel ? <p className="link-label">{linkLabel}</p> : null}
            <ArrowButton
              alt="right-arrow"
              className="right-arrow"
              href={link}
              onClick={handleArrowClick}
              orientation="right"
              theme={theme}
            />
          </div>
        ) : null}
      </div>

      {articlesList?.length
        ? articlesList.map((article) => (
          <ArticleItem
            articleData={article}
            handleLinkClick={handleLinkClick}
            key={article['@id']}
          />
        ))
        : null}
    </div>
  );
}

export default HubPreviewMainContent;
