/**
 * @module JourneyCard
 */
import React from 'react';
import CardWithTags from '../CardWithTags/CardWithTags';
import { fetchJourney } from '../../helpers/dataFetchers/findsFetchers';

/**
 * Represents a container component which renders a Card with Tags component, which contains an image with journey data (title, description, author) and link.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.additionalDescription] - The description for the specified journey.
 * @param {object} [props.fetchedJourney] - Optional pre-fetched journey data.
 * @param {boolean} [props.isFullImage] - Boolean flag indicating whether or not there is a full-width image.
 * @param {string} props.journey - The journey uuid of which to fetch data.
 * @param {object} [props.preload] - Optional preloaded journey data.
 *
 * @returns {React.ReactElement} The JourneyCard component.
 */
const JourneyCard = ({
  additionalDescription,
  fetchedJourney,
  isFullImage,
  journey,
  preload,
}) => {
  const [data, setData] = React.useState(fetchedJourney || preload);

  /**
   * Convenience effect to fetch and set data.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!(fetchedJourney || preload)) {
        setData(await fetchJourney({ journey }));
      } else {
        setData(fetchedJourney || preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey]);

  return data ? (
    <CardWithTags
      description={additionalDescription}
      hasFullWidthImage={isFullImage}
      image={data.image}
      isArticle={false}
      isSearchResult={false}
      link={data.slug ? `/journeys/${data.slug}` : ''}
      tags={data['mgnl:tags'] || []}
      title={data.title}
    />
  ) : null;
};

export default JourneyCard;
