/* eslint-disable no-unused-vars */
/**
 * @module CrossMark
 */
import React from 'react';

/**
 * Renders an SVG cross mark icon.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.fillColor] - The fill color for the SVG path. Defaults to '#E6DF69'.
 *
 * @returns {React.ReactElement|null}  A list item representing the tag filter or null if tagFilter is falsy.
 */
function CrossMark({ fillColor = '#E6DF69' }) {
  return (
    <svg
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06253 9.71143L0.288574 8.93747L4.22607 4.99997L0.288574 1.06247L1.06253 0.288513L5.00003 4.22601L8.93753 0.288513L9.71149 1.06247L5.77399 4.99997L9.71149 8.93747L8.93753 9.71143L5.00003 5.77393L1.06253 9.71143Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default CrossMark;
