/**
 * @module Sidebar
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import './Sidebar.scss';

const Sidebar = ({ mainarea, sidebar, metadata }) => {
  return (
    <section className="sidebar-wrapper">
      {mainarea ? (
        <div className="sidebar-mainarea">
          <EditableArea
            content={mainarea}
            parentTemplateId={metadata?.['mgnl:template']}
          />
        </div>
      ) : null}
      {sidebar ? (
        <div className="sidebar-sidebar">
          <EditableArea
            content={sidebar}
            parentTemplateId={metadata?.['mgnl:template']}
          />
        </div>
      ) : null}
    </section>
  );
};

export default Sidebar;
