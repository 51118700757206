/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module ArticleItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Tags from '../Tags/Tags';

/**
 * Represents an article item for the HubPreview articles list.
 *
 * @param {object} props - The props object.
 * @param {object} props.articleData - The object containing the article data.
 * @param {Function} props.handleLinkClick - The callback function for when the article link is clicked.
 *
 * @returns {React.ReactElement} - The ArticleItem component.
 */
function ArticleItem({ articleData, handleLinkClick }) {
  if (!articleData) {
    return null;
  }

  const {
    '@name': name,
    author,
    description,
    duration,
    'mgnl:tags': tags,
    title,
  } = articleData;

  return (
    <div className="article-wrapper" data-testid="lc-article-item">
      {tags?.length ? <Tags tags={tags} /> : null}
      {name && title ? (
        <a className="subtitle-ref" href={`/${name}`} onClick={handleLinkClick}>
          <h3 className="subtitle">{title}</h3>
        </a>
      ) : null}
      {author || duration ? (
        <p className="author-duration">
          {author || ''} {duration || ''}
        </p>
      ) : null}
      {description ? <p className="description">{description}</p> : null}
      <hr className="splitter" />
    </div>
  );
}

export default ArticleItem;
