/**
 * @module ImageWithParts
 */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import _ from 'lodash';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import writingIcon from '../../assets/writing-logo.svg';
import listeningIcon from '../../assets/listening-logo.svg';
import watchingIcon from '../../assets/watching-logo.svg';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCImage from '../LCImage/LCImage';
import {
  ACTIONS,
  EVENTS,
  FALLBACK_IMAGE,
  ICON_TYPES,
} from '../../helpers/constants';
import { fetchJourney } from '../../helpers/dataFetchers/findsFetchers';
import Tags from '../Tags/Tags';
import './ImageWithParts.scss';

/**
 * Represents a clickable container holding image, icon, tag, and text elements.
 *
 * @param {object} props - The component props object.
 * @param {object} props.algoliaFields - Object containing algolia fields for tracking.
 * @param {string} [props.buttonLabel] - Optional button label for the component call-to-action (Default: 'Start Journey').
 * @param {boolean} [props.isListing] - Optional boolean denoting whether or not the component is a listing.
 * @param {string} props.journey - The journey uuid value.
 * @param {object} [props.journeyData] - Optional object of journey data.
 * @param {object} [props.preload] - Optional object of preloaded journey data.
 *
 * @returns {React.ReactElement} The ImageWithParts component.
 */
const ImageWithParts = ({
  algoliaFields,
  buttonLabel = 'Start Journey',
  isListing = false,
  journey,
  journeyData = false,
  preload,
  ...passThroughProps
}) => {
  const { user } = useAuth();
  const [data, setData] = React.useState(
    journeyData || preload || passThroughProps?.data,
  );

  /**
   * Single-run convenience effect to fetch and set data for the component.
   *
   * Note: The additional passThroughProps conditional is included to ensure
   * inner-component state management logic support when in test mode.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!(journeyData || preload || passThroughProps?.data)) {
        setData(await fetchJourney({ journey }));
      } else {
        setData(journeyData || preload || passThroughProps?.data);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIconByType = (iconType) => {
    const iconId = uuidv4();
    let icon;
    let element;
    switch (iconType) {
      case ICON_TYPES.audio:
        icon = listeningIcon;
        break;
      case ICON_TYPES.text:
        icon = writingIcon;
        break;
      case ICON_TYPES.video:
        icon = watchingIcon;
        break;
      default:
        icon = false;
        break;
    }
    if (icon) {
      element = <img alt="Icon" className="icon" key={iconId} src={icon} />;
    }
    return element;
  };

  /**
   * Convenience function to return list of icon types.
   *
   * Note: Ignore directive added purely due to lack of need to cover the slight
   * data variance of from where the types are derived. The 'fallback' in this
   * logic assumes the `content` attribute of the data is full article data, but
   * for testing, it is sufficient to simplify the data and stick to the first
   * conditional check to directly set `types` on the data.
   *
   * @returns {Array} Array list of icon types.
   */
  /* istanbul ignore next */
  const iconTypes = () => {
    if (data?.types) {
      return data.types;
    }
    return data?.content?.map((article) => article.type);
  };

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrides - Optional object of override values.
   */
  function callAnalytics(event, overrides) {
    callSegmentTrack({
      event: overrides?.eventName || EVENTS.buttonAction,
      properties: {
        action: overrides?.action || ACTIONS.clicked,
        component: overrides?.component || 'Image with Parts',
        component_url: overrides?.component_url,
        index: algoliaFields?.index,
        label: overrides?.label,
        logged_in: !!user,
        position: algoliaFields?.position,
        preferred_campus: null,
        productID: algoliaFields?.productID,
        queryID: algoliaFields?.queryID,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for element click.
   *
   * Note: If test override is set, do not redirect and change window location.
   * The ignore is in place to ensure proper reporting in test coverage report.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleOnClick(event) {
    callAnalytics(event, {
      component_url: `/journeys/${data.slug}`,
      label: data.title,
    });
  }

  /**
   * Handler function for button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    callAnalytics(event, {
      component_url: `/journeys/${data?.slug}`,
      label: buttonLabel,
    });
  }

  return (
    <div
      className={`image-with-parts-wrapper${isListing ? ' listing' : ''}`}
      data-testid="lc-image-with-parts-wrapper"
    >
      {data && Object.keys(data).length ? (
        <div
          className="image-with-parts-content zoom-effect"
          data-testid="lc-image-with-parts-content"
          draggable={false}
        >
          <a
            aria-label={data.title}
            className="card-main-link"
            draggable={false}
            href={`/journeys/${data?.slug}`}
            onClick={handleOnClick}
          />
          <div className="image-container">
            <div className="gradient"></div>
            <LCImage
              alt="journey"
              className="image"
              src={data.image || FALLBACK_IMAGE}
              width={500}
            />
          </div>
          <div className="icons-container">
            {_.uniq(iconTypes(), false).map((iconType) =>
              getIconByType(iconType),
            )}
          </div>
          <div className="bottom-content">
            <Tags tags={data['mgnl:tags'] || []} />
            <h2 className="title">{data.title}</h2>
            <span
              className="parts-tag"
              data-testid="lc-image-with-parts-tag-length"
            >{`${data.content?.length} part${
              data.content?.length === 1 ? '' : 's'
            }`}</span>
            <ButtonItem
              className="start-button"
              data-testid="lc-image-with-parts-start-button"
              onClick={handleButtonClick}
              style="btn-primary"
              target={'_self'}
              text={buttonLabel}
              url={`/journeys/${data?.slug}`}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageWithParts;
