/**
 * @module ArticlePageHero
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import ButtonItem from '../ButtonItem/ButtonItem';
import Share from '../Share/Share';
import { ACTIONS, EVENTS } from '../../helpers/constants';

/**
 * Represents flexible and dynamic content for article button and Share elements.
 *
 * @param {object} props - The component props object.
 * @param {string} props.buttonLabel - The button label text value.
 * @param {string} [props.className] - Optional class name value to attribute to the main container div element.
 * @param {ShareData} props.data - Object of share data.
 * @param {boolean} props.hasFeaturedVideo - Boolean flag denoting whether or not the component has a featured video.
 * @param {boolean} [props.showShareTitle] - Optional boolean flag denoting whether or not to include the Share title alongside the lineup of icons.
 * @param {Function} props.toggleModal - Handler function for featured video button item click event.
 *
 * @returns {React.ReactElement} The FlexContent component.
 */
function FlexContent({
  buttonLabel,
  className,
  data,
  hasFeaturedVideo,
  showShareTitle,
  toggleModal,
}) {
  const { user } = useAuth();

  /**
   * Handler function for button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Article Page Hero',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: buttonLabel,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (toggleModal && typeof toggleModal === 'function') {
      toggleModal(event);
    }
  }

  return (
    <div
      className={`flex-content ${className || ''}`.trim()}
      data-testid="lc-flex-content"
    >
      {hasFeaturedVideo ? (
        <div>
          <ButtonItem
            className="action-button"
            onClick={handleButtonClick}
            style="btn-primary"
            text={buttonLabel}
          />
        </div>
      ) : null}
      <Share data={data} showShareTitle={showShareTitle} />
    </div>
  );
}

export default FlexContent;
