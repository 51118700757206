/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module Breadcrumbs
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import {
  ACTIONS,
  ENDPOINT_WORKSPACE_MAP,
  EVENTS,
  MGNL_ENV_VARS,
} from '../../helpers/constants';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ initPageNames = [], isArticle = false }) => {
  const { user } = useAuth();
  const [pageNames, setPageNames] = React.useState([]);
  const [pageLinks, setPageLinks] = React.useState([]);

  const getPageName = async (path, i) => {
    let name = 'Page';
    if (initPageNames.length > i) {
      name = initPageNames[i];
    } else {
      const current = await getMagnoliaItem({
        caller: 'src/middleware/magnoliaMiddleware.js',
        mgnlEnvVars: MGNL_ENV_VARS,
        path: `${process.env.REACT_APP_MGNL_API_NAV_FINDS}?@path=${path}`,
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      });
      if (current?.results?.length > 0 && current.results[0].title) {
        name = current.results[0].title;
      }
    }
    return name;
  };

  /**
   * Handler function for link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Breadcrumbs',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  React.useEffect(() => {
    async function fetchData() {
      let baseUrl = '';
      const pathname = `${window.location.pathname}`;
      /* istanbul ignore next*/
      if (!pathname.includes('.magnolia/admincentral')) {
        baseUrl = pathname
          .split('#')[0]
          .replace(/\/*\s*$/, '')
          .replace('.html', '');
      } else {
        baseUrl = pathname
          .split('#')[1]
          .substring(
            pathname.lastIndexOf(':detail;'),
            pathname.lastIndexOf(':edit'),
          )
          .replace(/\/*\s*$/, '')
          .replace('.html', '');
      }

      const parts = baseUrl.split('/');

      if (parts.length > 1 && parts[0] === '') {
        parts.shift();
      }
      /* istanbul ignore next*/
      if (parts[0] === 'magnoliaAuthor' || parts[0] === 'magnoliaPublic') {
        parts.shift();
      }
      if (
        parts.length > 1 &&
        (parts[0] === 'finds' || parts[0] === 'finds-articles')
      ) {
        parts.shift();
      }

      let currentUrl = '/';
      const tempUrls = isArticle ? ['/articles/'] : [];
      parts.forEach((p) => {
        currentUrl += `${p}/`;
        tempUrls.push(currentUrl);
      });
      setPageLinks(tempUrls);

      Promise.all(
        tempUrls.map((path, i) => {
          return getPageName(path, i);
        }),
      ).then((data) => {
        setPageNames(data);
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      aria-label="Breadcrumb"
      className="breadcrumb"
      data-testid="lc-breadcrumbs"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      <div className="container">
        <div
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope={true}
          itemType="https://schema.org/ListItem"
        >
          <a className="breadcrumb-link" href="/" itemProp="item">
            <span className="breadcrumb-text" itemProp="name">
              {`Home`}{' '}
            </span>
          </a>
          <meta content="1" itemProp="position" />
        </div>

        {pageNames?.length
          ? pageNames.map((page, i, arr) => (
              <div
                className={`breadcrumb-item${
                  arr.length === i + 1 ? ' active' : ''
                }`}
                itemProp="itemListElement"
                itemScope={true}
                itemType="https://schema.org/ListItem"
                key={page}
              >
                <span className="breadcrumb-separator">{` / `}</span>
                <a
                  className={`breadcrumb-link`}
                  href={
                    pageLinks.length > i
                      ? pageLinks[i]
                      : /* istanbul ignore next*/ '#'
                  }
                  itemProp="item"
                  onClick={handleLinkClick}
                >
                  <span className={`breadcrumb-text`} itemProp="name">
                    {page}
                  </span>
                </a>
                <meta content={i + 2} itemProp="position" />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Breadcrumbs;
