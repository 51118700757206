/**
 * @module ChevronUp
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an up-pointing chevron arrow shape.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - The additional CSS class name for custom styling.
 * @param {string} [props.color] - Optional color for the chevron path.
 *
 * @returns {React.ReactElement} The ChevronUp component.
 */
function ChevronUp({ className, color }) {
  return (
    <svg
      className={className}
      data-testid="lc-chevron-up"
      fill="none"
      height="6"
      viewBox="0 0 10 6"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.35432 3.36668L0.92148 0.000441085L5.53246e-08 0.897682L4.5425 5.35207C4.66409 5.47059 4.82856 5.53711 5 5.53711C5.17144 5.53711 5.33591 5.47059 5.4575 5.35207L10 0.897682L9.08501 0.000441183L5.65217 3.36668L5.00324 4.03711L4.35432 3.36668Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
}

export default ChevronUp;
