/**
 * @module NextSteps
 */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import writingIcon from './writing-logo.svg';
import listeningIcon from './listening-logo.svg';
import watchingIcon from './watching-logo.svg';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import {
  ENDPOINT_WORKSPACE_MAP,
  ICON_TYPES,
  MGNL_ENV_VARS,
} from '../../helpers/constants';
import NextStepsRowLayout from './NextStepsRowLayout';
import NextStepsColumnLayout from './NextStepsColumnLayout';
import useTheme from '../../hooks/useTheme';
import './NextSteps.scss';

/**
 * Represents a wrapper with Next Steps content of title/link pairs.
 *
 * @param {object} props - The component props object.
 * @param {object} props.items - Data object of next steps items.
 * @param {boolean} [props.rowLayout] - Boolean flag denoting whether or not to use row layout (Default: false).
 * @param {string} props.theme - The theme value.
 * @param {string} props.title - The component title value.
 *
 * @returns {React.ReactElement} The NextSteps component.
 */
const NextSteps = ({ items, rowLayout, theme, title, ...passThroughProps }) => {
  const { pageTheme } = useTheme();
  const [content, setContent] = React.useState(
    passThroughProps?.contentOverride || [],
  );
  const appliedTheme = getAppliedTheme(pageTheme, theme);

  /**
   * Convenience function to return an img tag for the specified icon type, or null if invalid type provided.
   *
   * @param {'audio'|'text'|'video'} iconType - The icon type (one of the values from ICON_TYPES constant).
   *
   * @returns {HTMLImageElement} The img tag with icon-specific src attribute, or null if invalid type provided.
   */
  const getIcon = (iconType) => {
    const iconId = uuidv4();
    let icon;
    switch (iconType) {
      case ICON_TYPES.audio:
        icon = listeningIcon;
        break;
      case ICON_TYPES.text:
        icon = writingIcon;
        break;
      case ICON_TYPES.video:
        icon = watchingIcon;
        break;
      default:
        icon = false;
        break;
    }
    return icon ? (
      <img
        alt={`${iconType} icon`}
        className="icon"
        data-type={iconType}
        key={iconId}
        src={icon}
      />
    ) : null;
  };

  const getSteps = () => {
    if (!items?.['@nodes']) {
      return [];
    }
    return items['@nodes'].map((i) => items[i]);
  };

  /**
   * Convenience function to fetch data and set item content.
   *
   * @param {object} item - Item data object.
   *
   * @returns {object} Data object of item content.
   */
  const getItemContent = async (item) => {
    const forText = item?.smallTitle || 'FOR';
    const forTarget = item?.largeTitle || '';
    const id = item['@id'];
    let label = '';
    let link = '#';
    let type = false;
    if (item?.content?.field === 'article') {
      const data = await getMagnoliaItem({
        caller: 'src/components/NextSteps/NextSteps',
        mgnlEnvVars: MGNL_ENV_VARS,
        path: `${process.env.REACT_APP_MGNL_API_ARTICLES_FINDS}?jcr:uuid=${item?.content?.article}`,
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      }); // NOSONAR
      const itemData = data?.results?.length ? data.results[0] : null;
      label = itemData?.title || '';
      link = itemData?.['@name'] ? `/${itemData['@name']}` : '#';
      type = getIcon(itemData?.type || false);
    } else if (item?.content?.field === 'journey') {
      const data = await getMagnoliaItem({
        caller: 'src/components/NextSteps/NextSteps',
        mgnlEnvVars: MGNL_ENV_VARS,
        path: `/.rest/delivery/finds/journey?jcr:uuid=${item?.content?.journey}`,
        workspaceMap: ENDPOINT_WORKSPACE_MAP,
      }); // NOSONAR
      const itemData = data?.results?.length ? data.results[0] : null;
      label = itemData?.title || '';
      link = itemData?.slug ? `/journeys/${itemData.slug}` : '#';
      type = getIcon(itemData?.type || false);
    } else if (item?.content?.field === 'link') {
      label = item?.content?.description || '';
      link = item?.content?.link || '#';
      type = getIcon(item?.content?.type || false);
    }

    return {
      forTarget,
      forText,
      id,
      label,
      link,
      type,
    };
  };

  /**
   * Convenience effect to fetch data, map steps, and set content.
   */
  React.useEffect(() => {
    async function fetchData() {
      Promise.all(
        getSteps().map((i) => {
          return getItemContent(i);
        }),
      ).then((data) => setContent(data));
    }

    if (!passThroughProps?.contentOverride) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`next-steps-wrapper container ${appliedTheme}`}>
      {title ? <h2 className="next-steps-title">{title}</h2> : null}
      <div className={`next-steps-contents ${rowLayout ? 'row' : 'column'}`}>
        {rowLayout ? (
          <NextStepsRowLayout content={content} />
        ) : (
          <NextStepsColumnLayout content={content} />
        )}
      </div>
    </div>
  );
};

export default NextSteps;
