/**
 * @module ArticleNavigation
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ArrowButton from '../ArrowButton/ArrowButton';
import './ArticleNavigation.scss';

/**
 * Represents a component containing navigation elements for navigating between articles.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - The additional CSS class name for custom styling.
 * @param {string} [props.nextText] - The text to display for the "next" link.
 * @param {string} props.nextUrl - The URL for the "next" link.
 * @param {Function} [props.onNextClick] - Callback function for when the "next" link is clicked.
 * @param {Function} [props.onPrevClick] - Callback function for when the "previous" link is clicked.
 * @param {string} [props.prevText] - The text to display for the "previous" link.
 * @param {string} props.prevUrl - The URL for the "previous" link.
 *
 * @returns {React.ReactElement} - The ArticleNavigation component.
 */
function ArticleNavigation({
  className,
  nextText,
  nextUrl,
  onNextClick,
  onPrevClick,
  prevText,
  prevUrl,
}) {
  /**
   * Handler function for click events.
   *
   * @param {Function} callback - Callback function to invoke.
   */
  function handleClick(callback) {
    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  return (
    <div
      className={`article-navigation ${className || ''}`.trim()}
      data-testid="lc-article-navigation"
    >
      <a
        className="back-link"
        href={prevUrl || '#'}
        onClick={() => handleClick(onPrevClick)}
      >
        <ArrowButton alt="Back Icon" className="back-icon" orientation="left" />
        {prevText ? <div className="back-text">{prevText}</div> : null}
      </a>
      <a
        className="next-link"
        href={nextUrl || '#'}
        onClick={() => handleClick(onNextClick)}
      >
        <ArrowButton
          alt="Next Icon"
          className="next-icon"
          orientation="right"
        />
        {nextText ? <div className="next-text">{nextText}</div> : null}
      </a>
    </div>
  );
}

export default ArticleNavigation;
