/**
 * @module HomeHero
 */
import React from 'react';
import Slider from 'react-slick';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import ArrowButton from '../ArrowButton/ArrowButton';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCImage from '../LCImage/LCImage';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import Tags from '../Tags/Tags';
import useTheme from '../../hooks/useTheme';
import './HomeHero.scss';
import './slick.scss';

const HomeHero = ({ gradientColor = 'dark', slides, theme }) => {
  const { user } = useAuth();
  const { pageTheme } = useTheme();
  const [current, setCurrent] = React.useState(0);
  const slidesList = slides['@nodes']
    ? slides['@nodes'].map((s) => slides[s])
    : [];
  const totalSlides = slidesList.length;
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  let sliderRef = React.useRef(null);
  const { width } = useWindowSize();
  const settings = {
    accessibility: false,
    afterChange: /* istanbul ignore next*/ (curr) => setCurrent(curr),
    arrows: false,
    infinite: true,
  };

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {object} params - The function params object.
   * @param {Event} params.event - The Event object associated with the analytics call.
   * @param {Event} [params.label] - Optional label value.
   */
  function callAnalytics({ event, label }) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Home Hero',
        component_url: null, // No URLs associated with this component to track.
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for previous slide click.
   *
   * @param {Event} event - The Event object associated with the action.
   */
  function previousSlide(event) {
    callAnalytics({ event, label: 'Previous' });
    sliderRef.slickPrev();
  }

  function nextSlide(event) {
    callAnalytics({ event, label: 'Next' });
    sliderRef.slickNext();
  }

  /**
   * Handler function for slide button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    callAnalytics({ event });
  }

  return slidesList?.length ? (
    <div className="home-hero-slider-wrapper" data-testid="lc-home-hero">
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        {slidesList.map((slide, i) => (
          <div
            className={`home-hero-wrapper ${appliedTheme} gradient-${gradientColor}`}
            key={i}
          >
            <div className="background-gradient"></div>

            {slide?.heroImage ? (
              <LCImage
                alt="slide"
                className="background-image"
                src={slide.heroImage}
                width={width}
              />
            ) : null}
            <div className="content-wrapper">
              <div className="bottom-content container">
                {slide?.['mgnl:tags']?.length ? (
                  <Tags tags={slide['mgnl:tags']} />
                ) : null}
                {slide?.title ? <h1 className="title">{slide.title}</h1> : null}
                <div className="flex-content">
                  <ButtonItem
                    className="action-button"
                    onClick={handleButtonClick}
                    style="btn-primary"
                    text={slide.buttonLabel}
                    url={slide.url}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="slide-counter">
        <ArrowButton
          alt="Back Icon"
          className="icon"
          onClick={previousSlide}
          orientation="left"
          theme={appliedTheme}
        />
        <div className="counter">
          {current + 1}/{totalSlides}
        </div>
        <ArrowButton
          alt="Next Icon"
          className="icon"
          onClick={nextSlide}
          orientation="right"
          theme={appliedTheme}
        />
      </div>
    </div>
  ) : null;
};

export default HomeHero;
