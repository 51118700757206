/**
 * @module ArticleSchema
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Renders script with JSON-LD structured data for articles.
 *
 * @param {object} props - The properties that define the article schema.
 * @param {string} props.author - The name of the article's author.
 * @param {string} [props.authorUrl] - The URL of the author's page. Defaults to the current page URL.
 * @param {string} props.description - A brief description of the article.
 * @param {string} props.image - The URL of an image representing the article.
 * @param {string} props.timeRequired - The time required to read the article.
 * @param {string} props.title - The title of the article.
 * @param {string} [props.url] - The URL of the article. Defaults to the current page URL.
 *
 * @returns {React.ReactElement|null} A script element containing the JSON-LD data, or null if any prop is missing.
 */
function ArticleSchema({
  author,
  authorUrl = window?.location?.href,
  description,
  image,
  timeRequired,
  title,
  url = window?.location?.href,
}) {
  const allPropsValid = Object.values({
    author,
    authorUrl,
    description,
    image,
    timeRequired,
    title,
    url,
  }).every(Boolean);

  if (!allPropsValid) {
    return null;
  }

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: {
      '@type': 'Person',
      name: author,
      url: authorUrl,
    },
    description,
    headline: title,
    image,
    timeRequired,
    url,
  };

  return (
    <script
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
      type="application/ld+json"
    />
  );
}

export default ArticleSchema;
