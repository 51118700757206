/**
 * @module BigJourneyCard
 */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import listeningIcon from '../../assets/listening-logo.svg';
import watchingIcon from '../../assets/watching-logo.svg';
import writingIcon from '../../assets/writing-logo.svg';
import { FALLBACK_IMAGE, ICON_TYPES } from '../../helpers/constants';
import ButtonItem from '../ButtonItem/ButtonItem';
import LCImage from '../LCImage/LCImage';
import Tags from '../Tags/Tags';
import './BigJourneyCard.scss';

/**
 * Represents a container of Journey-related data including image, title, description, author, and call to action button.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.author] - The Journey author name.
 * @param {string} props.buttonLabel - The button label value.
 * @param {string} props.buttonLink - The button ULR link.
 * @param {string} [props.duration] - Optional duration value for the Journey.
 * @param {string} [props.image] - Optional Journey image src URL.
 * @param {boolean} [props.isDragging] - Optional boolean flag indicating whether or not the component is being interacted with in a dragging event.
 * @param {Function} [props.onClick] - Optional handler function for the component's button click event.
 * @param {string} props.part - The part number value of the Journey.
 * @param {Array} props.tags - Optional array of tags associated with the article (Default: []).
 * @param {string} [props.title] - The Journey card title.
 * @param {string} [props.type] - The Journey card type.
 *
 * @returns {React.ReactElement} The BigJourneyCard component.
 */
const BigJourneyCard = ({
  author,
  buttonLabel,
  buttonLink,
  duration,
  image,
  isDragging,
  onClick,
  part,
  tags,
  title,
  type,
}) => {
  /**
   * Convenience function to return an img tag for the specified icon type, or null if invalid type provided.
   *
   * @param {'audio'|'text'|'video'} iconType - The icon type (one of the values from ICON_TYPES constant).
   *
   * @returns {HTMLImageElement} The img tag with icon-specific src attribute, or null if invalid type provided.
   */
  const getIcon = (iconType) => {
    const iconId = uuidv4();
    let icon;
    switch (iconType) {
      case ICON_TYPES.audio:
        icon = listeningIcon;
        break;
      case ICON_TYPES.text:
        icon = writingIcon;
        break;
      case ICON_TYPES.video:
        icon = watchingIcon;
        break;
      default:
        icon = false;
        break;
    }
    return icon ? (
      <img
        alt={`${iconType} icon`}
        className="icon"
        data-type={iconType}
        key={iconId}
        src={icon}
      />
    ) : null;
  };

  /**
   * Handler function for main element click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleOnClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <div
      className={`big-journey-card-wrapper${
        isDragging ? ' disable-click' : ''
      } zoom-effect`}
      data-testid="lc-big-journey-card-wrapper"
    >
      <a
        aria-label={title}
        className="card-main-link"
        href={buttonLink}
        onClick={handleOnClick}
      />
      <div className="big-journey-card-container">
        {!!part ? <p className="card-part">PART {part}</p> : null}
        <div className="image-container">
          <div className="gradient">{getIcon(type)}</div>
          <LCImage
            className="image"
            src={image || FALLBACK_IMAGE}
            width={600}
          />
        </div>
        <div className="big-journey-card-content">
          {tags?.length ? <Tags tags={tags} /> : null}
          {title ? <h2 className="title">{title}</h2> : null}
          {author ? (
            <p className="author-duration">
              {[author, duration].filter(Boolean).join(' • ')}
            </p>
          ) : null}
          {buttonLabel && buttonLink ? (
            <ButtonItem
              className="start-button"
              style="btn-primary"
              target={'_self'}
              text={buttonLabel}
              url={buttonLink}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BigJourneyCard;
