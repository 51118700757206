/**
 * @module Spans
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Spans = () => {
  const MAXPATHNUM = 5;
  return (
    <>
      {[...Array(MAXPATHNUM)].map((x, i) => (
        <span className={`path${i + 1}`} key={`path-${uuidv4()}`} />
      ))}
    </>
  );
};
export default Spans;
