/**
 * @module FormStack
 */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const FormStack = ({ formstackFormSlug }) => {
  const id = uuidv4();

  /**
   * Handler function for iframe height check.
   * Note: Jest ignore added since conditional check is edge case protection.
   */
  const checkIframeHeight = () => {
    const iframe = document.getElementById(`formstack-${id}`);
    /* istanbul ignore next */
    if (iframe) {
      iframe.style.height = `${iframe.contentWindow.document.documentElement.scrollHeight}px`;
    }
  };

  /**
   * Convenience effect to check iframe height.
   * Note: Jest ignore added since conditional check is edge case protection.
   */
  React.useEffect(() => {
    const iframe = document.getElementById(`formstack-${id}`);
    /* istanbul ignore next */
    if (iframe) {
      const iframeWin = iframe.contentWindow || iframe;
      const iframeDoc = iframe.contentDocument || iframeWin.document;

      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(
          `<script src="${
            process.env.FORM_STACK_BASE_URL + formstackFormSlug
          }"></script>`,
        );
        iframeDoc.close();
      }
    }

    function handleResize() {
      checkIframeHeight();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <iframe
        data-testid="lc-formstack"
        frameBorder="0"
        id={`formstack-${id}`}
        onLoad={checkIframeHeight}
        title="Formstack"
        width="100%"
      ></iframe>
      <noscript>
        <a
          href="https://lifechurch.formstack.com/forms/baptism"
          title="Online Form"
        >
          Online Form - Baptism
        </a>
      </noscript>
    </>
  );
};

export default FormStack;
