/* global hbspt */
/**
 * @module Hubspot
 */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Hubspot = ({ hubspotFormID, className, onFormReady = () => {} }) => {
  const [id, setId] = React.useState('hubspot');

  React.useEffect(() => {
    // eslint-disable-next-line no-shadow
    const id = `hubspot_${uuidv4()}`;
    setId(id);
    const maxAttempts = 20; // at 500 millisecond intervals (below), this equates to a 10-second effort to create forms.
    let numAttempts = 0;
    const $script1 = document.createElement('script');
    $script1.src = '//js.hsforms.net/forms/v2-legacy.js';
    $script1.type = 'text/javascript';
    const $script2 = document.createElement('script');
    $script2.src = '//js.hsforms.net/forms/v2.js';
    $script2.type = 'text/javascript';
    document.body.appendChild($script1);
    document.body.appendChild($script2);

    const hubspot = setInterval(() => {
      /* istanbul ignore next */
      if (typeof hbspt !== 'undefined') {
        if (hbspt?.forms?.create) {
          hbspt.forms.create({
            css: '',
            cssClass: `class-applied-to-form ${className || ''}`,
            formId: hubspotFormID,
            onFormReady,
            portalId: 449781,
            submitButtonClass: 'btn btn-primary btn-medium mx-auto',
            target: `#${id}`,
          });
          clearInterval(hubspot);
        } else {
          numAttempts += 1;
          if (numAttempts >= maxAttempts) {
            clearInterval(hubspot);
          }
        }
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="class-applied-to-form"
      data-testid="lc-hubspot"
      id={id}
    ></div>
  );
};

export default Hubspot;
