/**
 * @module AudioWrapper
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import LCImage from '../LCImage/LCImage';
import './AudioWrapper.scss';
import './AudioPlayer.scss';

/**
 * Represents a wrapper containing an image and audio player for the specified data.
 *
 * @param {object} props - The component props object.
 * @param {string} props.audioSrc - The src value of the audio file for the audio player.
 * @param {string} [props.audioTitle] - Optional title of the audio file.
 * @param {string} [props.playerImage] - Optional src URL for the audio file's accompanying image.
 * @param {string} [props.subtext] - Optional subtext value of the audio file.
 *
 * @returns {React.ReactElement} The AudioWrapper component.
 */
const AudioWrapper = ({ audioSrc, audioTitle, playerImage, subtext }) => {
  return (
    <div className="audio-wrapper">
      {playerImage ? (
        <LCImage
          alt={'audio-featured-image'}
          className={'audio-image'}
          src={playerImage}
          width={200}
        />
      ) : null}
      {audioSrc || audioTitle || subtext ? (
        <div className="player-wrapper">
          {audioTitle || subtext ? (
            <div className="text-wrapper">
              {audioTitle ? <h3 className="title">{audioTitle}</h3> : null}
              {subtext ? <div className="subtext">{subtext}</div> : null}
            </div>
          ) : null}
          {audioSrc ? (
            <AudioPlayer
              className="audio-player"
              layout="horizontal"
              showFilledVolume={true}
              showJumpControls={false}
              src={audioSrc}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default AudioWrapper;
