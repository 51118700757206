/**
 * @module ArrowButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { COLOR_THEMES } from '../../helpers/constants';
import './ArrowButton.scss';

const orientations = {
  left: 'left',
  right: 'right',
};

/**
 * Represents a right-facing arrow.
 *
 * @returns {SVGElement} The right-facing arrow SVG element.
 */
function RightArrow() {
  return (
    <svg
      data-testid="arrow-button-right"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={'circle'} cx="16" cy="16" r="16" />
      <path
        className={'path2'}
        d="M16 32C19.1645 32 22.2579 31.0616 24.8891 29.3035C27.5203 27.5454 29.5711 25.0466 30.7821 22.1229C31.9931 19.1993 32.3099 15.9823 31.6926 12.8786C31.0752 9.77486 29.5513 6.92393 27.3137 4.68629C25.0761 2.44865 22.2251 0.924798 19.1214 0.307435C16.0177 -0.30993 12.8007 0.0069239 9.87706 1.21793C6.95344 2.42893 4.45458 4.47969 2.69648 7.11087C0.938377 9.74206 -5.95398e-06 12.8355 -6.23063e-06 16C-6.60161e-06 20.2435 1.6857 24.3131 4.68628 27.3137C7.68687 30.3143 11.7565 32 16 32ZM16 2C18.7689 2 21.4757 2.82108 23.778 4.35942C26.0803 5.89776 27.8747 8.08427 28.9343 10.6424C29.9939 13.2006 30.2712 16.0155 29.731 18.7313C29.1908 21.447 27.8574 23.9416 25.8995 25.8995C23.9416 27.8574 21.447 29.1908 18.7313 29.731C16.0155 30.2712 13.2006 29.9939 10.6424 28.9343C8.08426 27.8747 5.89776 26.0803 4.35942 23.778C2.82108 21.4757 1.99999 18.7689 1.99999 16C1.99999 12.287 3.47499 8.72601 6.1005 6.1005C8.72601 3.47499 12.287 2 16 2Z"
      />
      <path
        className={'path1'}
        d="M20.8296 16.6454L17.4634 20.0783L18.3606 20.9998L22.815 16.4573C22.9335 16.3357 23.0001 16.1712 23.0001 15.9998C23.0001 15.8283 22.9335 15.6638 22.815 15.5423L18.3606 10.9998L17.4634 11.9147L20.8296 15.3476L9.00006 15.3476L9.00006 16.6454L20.8296 16.6454Z"
      />
    </svg>
  );
}

/**
 * Represents a left-facing arrow.
 *
 * @returns {SVGElement} The left-facing arrow SVG element.
 */
function LeftArrow() {
  return (
    <svg
      data-testid="arrow-button-left"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={'circle'} cx="16" cy="16" r="16" />
      <path
        className={'path2'}
        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0ZM16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91974 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05844 6.10051 6.1005C8.05845 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72881 18.7994 2.00606 21.3576 3.06569C23.9157 4.12531 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30Z"
      />
      <path
        className={'path1'}
        d="M11.1704 15.3541L14.5367 11.9212L13.6394 10.9998L9.18504 15.5423C9.06652 15.6638 9 15.8283 9 15.9998C9 16.1712 9.06652 16.3357 9.18504 16.4573L13.6394 20.9998L14.5367 20.0848L11.1704 16.6519H23V15.3541H11.1704Z"
      />
    </svg>
  );
}

/**
 * Represents an arrow button with either left or right orientation.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component.
 * @param {string} [props.href] - Optional href to attribute to the component. If set, will return an anchor element. If not set, will return a button element.
 * @param {Function} [props.onClick] - Optional handler function for click event.
 * @param {'left'|'right'} [props.orientation] - Orientation placement of the arrow.
 * @param {string} [props.theme] - The theme for the arrow.
 *
 * @returns {HTMLElement} An anchor or button element wrapping the SVG left or right oriented arrow element.
 */
function ArrowButton({
  className = '',
  href = null,
  onClick,
  orientation = orientations.right,
  theme = COLOR_THEMES['dark-mode'],
}) {
  const Tag = href ? 'a' : 'button';

  /**
   * Handler function for the element click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleClick(event) {
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  }

  return (
    <Tag
      className={`arrow-btn yellow ${orientation} ${theme} ${className}`.trim()}
      data-testid="arrow-button"
      href={href}
      onClick={handleClick}
    >
      {orientation === orientations.left ? <LeftArrow /> : <RightArrow />}
    </Tag>
  );
}

export default ArrowButton;
