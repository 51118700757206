/**
 * @module JourneyDetail
 */
import React from 'react';
import { fetchJourney } from '../../helpers/dataFetchers/findsFetchers';
import JourneyHero from '../JourneyHero/JourneyHero';
import TextImageCTA from '../TextImageCTA/TextImageCTA';
import Carousel from '../Carousel/Carousel';
import EmbedItem from '../EmbedItem/EmbedItem';
import './JourneyDetail.scss';

/**
 * Represents a wrapper for a journey containing a JourneyHero component, optional carousel, featured content, form, and calls to action.
 *
 * @param {object} props - The component props object.
 * @param {string} props.journey - The journey uuid value.
 * @param {object} [props.journeyData] - Optional object of journey data.
 * @param {object} [props.preload] - Optional object of preloaded journey data.
 *
 * @returns {React.ReactElement} The JourneyDetail component.
 */
const JourneyDetail = ({ journey, journeyData, preload }) => {
  const [data, setData] = React.useState(journeyData || preload);

  /**
   * Single-run convenience effect to fetch and set data for the component.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!(journeyData || preload)) {
        setData(await fetchJourney({ journey }));
      } else {
        setData(journeyData || preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="journey">
      {data ? (
        <JourneyHero
          image={data.image}
          name={data.name}
          parts={`${data.content?.length} part${
            data.content?.length > 1 ? 's' : ''
          }`}
          tags={data?.metadata?.['mgnl:tags'] || []}
          title={data.title}
        />
      ) : null}
      <div className="journey-content">
        <h2 className={'journey-start-heading container'}>
          Your Journey Starts Here
        </h2>
        {data?.content ? (
          <div className="journey-article-list container">
            {
              <Carousel
                articles={data.content}
                parentJourneySlug={data.slug || ''}
              />
            }
          </div>
        ) : null}
      </div>
      {data?.featuredContent || data?.form ? (
        <div className="container journey-bottom">
          {data?.form ? (
            <div className="journey-form">
              <EmbedItem
                type={{ field: 'hubspot', hubspotFormID: data.form }}
              ></EmbedItem>
            </div>
          ) : null}
          {data?.featuredContent ? (
            <div className="journey-featured">
              <TextImageCTA
                backgroundImage={data?.featuredContent?.image || ''}
                buttonLabel={data?.featuredContent?.buttonText || ''}
                buttonLink={data?.featuredContent?.buttonUrl || '#'}
                link={{
                  linkTitle: data?.featuredContent?.buttonText || '',
                  url: data?.featuredContent?.buttonUrl || '#',
                }}
                title={data?.featuredContent?.title || ''}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default JourneyDetail;
