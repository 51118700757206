/**
 * @module SocialMediaLinks
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Spans from '../../IconSpans/Spans';
import '../Footer.scss';

/**
 * Represents a list of HTML markup for social media links.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onLinkClick - Handler function for link click event.
 * @param {object} props.socialMediaLinks - Data object of social media links.
 *
 * @returns {React.ReactElement} The SocialMediaLinks component.
 */
function SocialMediaLinks({
  onLinkClick,
  socialMediaLinks,
  ...passThroughProps
}) {
  if (!socialMediaLinks || !socialMediaLinks['@nodes']) {
    return null;
  }

  return (
    <div className="socials" data-testid={passThroughProps?.['data-testid']}>
      <div className="social-media-links">
        {socialMediaLinks['@nodes'].map((item) => {
          const socialItem = socialMediaLinks[item];
          return (
            <a
              className="social-link"
              href={socialItem.socialMediaUrl}
              key={socialItem['@id']}
              onClick={(event) => {
                onLinkClick({ event, socialItem });
              }}
              rel="noreferrer"
              target="_blank"
            >
              <span className={`icon ${socialItem.socialMediaType || ''}`}>
                <Spans />
              </span>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default SocialMediaLinks;
