/**
 * @module BrightEdge
 */

/**
 * Convenience effect to return markup for BrightEdge Autopilot self-connecting
 * pages script tag and accompanying link tag for global Life.Church branded
 * style overrides of its elements (if `cssSrc` provided).
 *
 * Note: The line break is intentionally added to the function's local
 * `cssLinkTag` variable so it will have a line break when added to the end of
 * the main return value which is a single line with `${}` injected variables.
 *
 * @param {BrightEdgeParams} brightEdgeParams - The function BrightEdgeParams object.
 * @param {string} brightEdgeParams.configId - The BrightEdge Autopilot account config id value, specific to our Life.Church account (e.g. 'f00000000309362').
 * @param {string} brightEdgeParams.cssSrc - Optional URL to CSS file for style overrides (e.g. 'https://cms-assets.life.church/assets/css/brightedge.min.css').
 * @param {'body'|'head'} brightEdgeParams.placement - Placement source of the returned injection code.
 *
 * @returns {string} The script and link tags to inject in the specified placement.
 */
export function brightEdgeAutoPilotScript({ configId, cssSrc, placement }) {
  const cssLinkTag = cssSrc
    ? `
  <link rel="stylesheet" href="${cssSrc}">`
    : '';
  if (placement === 'head') {
    return `<script src="//cdn.bc0a.com/autopilot/${configId}/autopilot_sdk.js"></script>${cssLinkTag}`;
  }
  if (placement === 'body') {
    return `<div class="be-ix-link-block"></div>${cssLinkTag}`;
  }
  return '';
}
