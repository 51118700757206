/**
 * @module useModals
 */
import React from 'react';
import { ModalContext } from '../../context/ModalContext';

/**
 * React hook for accessing `ModalContext`. Must be used inside of a `ModalProvider` context.
 *
 * @throws {Error} Throws an error if used outside of a `ModalProvider` context.
 *
 * @returns {React.Context} The ModalContext values.
 *
 * @example
 * import useModals from 'hooks/useModals';
 *
 * function SampleComponent() {
 *   const { modalTriggers } = useModals();
 *
 *   return (
 *     <ModalContainerComponent modalTriggers={modalTriggers} />
 *   )
 * }
 */
function useModals() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModals must be used within a ModalProvider');
  }
  return context;
}

export default useModals;
