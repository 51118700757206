/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module TagFilter
 */
import React from 'react';
import CrossMark from '../Icons/CrossMark';

/**
 * Renders an individual tag filter.
 *
 * @param {object} props - The component props object.
 * @param {string|null} props.activeFilter - The currently active filter tag.
 * @param {Function} props.handleTagClick - Callback function to handle tag click events.
 * @param {string} props.tagFilter - The tag filter to render.
 *
 * @returns {React.ReactElement|null}  A list item representing the tag filter or null if tagFilter is falsy.
 */
function TagFilter({ activeFilter, handleTagClick, tagFilter }) {
  if (!tagFilter) {
    return null;
  }
  const isActive = tagFilter === activeFilter;
  const activeClassName = isActive ? 'active-tag' : '';
  return (
    <li
      className={`tag-filter ${activeClassName}`.trim()}
      data-testid={`tag-filter-${tagFilter}`}
      key={tagFilter}
      onClick={() => handleTagClick(tagFilter)}
    >
      <span>{tagFilter}</span>
      <CrossMark />
    </li>
  );
}

export default TagFilter;
