/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HubPreviewFeaturedContent
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LCImage from '../LCImage/LCImage';
import Tags from '../Tags/Tags';
import { FALLBACK_IMAGE } from '../../helpers/constants';

/**
 * Represents the featured section of the HubPreview component.
 *
 * @param {object} props - The component props object.
 * @param {object} props.featuredData - The object containing the featured data.
 * @param {object} props.handleLinkClick - Handler function for the section link click event.
 * @param {object} props.handleWrapperClick - Handler function for the section wrapper click event.
 *
 * @returns {React.ReactElement} - The HubPreviewFeaturedContent component.
 */
function HubPreviewFeaturedContent({
  featuredData,
  handleLinkClick,
  handleWrapperClick,
}) {
  if (!featuredData) {
    return null;
  }

  const {
    '@name': name,
    'mgnl:tags': tags,
    author,
    duration,
    featuredImage,
    heroImage,
    title,
  } = featuredData;

  return (
    <div
      className="featured-content"
      data-testid="lc-hub-preview-featured-content"
    >
      <div className="image-content-wrapper" onClick={handleWrapperClick}>
        <div className="image-gradient"></div>
        <LCImage
          className="image"
          src={featuredImage || heroImage || FALLBACK_IMAGE}
          width={800}
        />
        <div className="image-content">
          {tags?.length ? <Tags tags={tags} /> : null}
          {name && title ? (
            <a
              className="image-title-ref"
              href={`/${name}`}
              onClick={handleLinkClick}
            >
              <h3 className="image-title">{title}</h3>
            </a>
          ) : null}
          {author || duration ? (
            <p className="image-author-duration">
              {author || ''} {duration || ''}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HubPreviewFeaturedContent;
