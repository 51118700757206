import  { useMemo } from 'react';
import algoliasearch from 'algoliasearch/dist/algoliasearch-lite.umd.js';

export default function useAlgoliaClient() {
    
    const useClient = useMemo(() => {
        const client = algoliasearch(
            process.env.FINDS_ALGOLIA_API_ID,
            process.env.FINDS_ALGOLIA_API_SEARCH_KEY,
        );
        return client;
    }, []);
    return useClient;
}

