/**
 * @module useTheme
 */
import React from 'react';
import { ThemeContext } from '../../context/ThemeContext';

/**
 * React hook for accessing `ThemeContext`. Must be used inside of a `ThemeProvider` context.
 *
 * @throws {Error} Throws an error if used outside of a `ThemeProvider` context.
 *
 * @returns {React.Context} The ThemeContext values.
 *
 * @example
 * import useTheme from 'hooks/useTheme';
 *
 * function SampleComponent() {
 *   const { pageTheme, storePageTheme } = useTheme();
 *   ...
 * }
 */
function useTheme() {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be within a ThemeProvider');
  }
  return context;
}

export default useTheme;
