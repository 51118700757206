/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @module Carousel
 */
import React, { useRef, useState } from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { fetchCarousel } from '../../helpers/dataFetchers/findsFetchers';
import { ACTIONS, EVENTS, ICON_TYPES } from '../../helpers/constants';
import BigJourneyCard from '../BigJourneyCard/BigJourneyCard';
import SmallJourneyCard from '../SmallJourneyCard/SmallJourneyCard';
import ArrowButton from '../ArrowButton/ArrowButton';
import './Carousel.scss';

/**
 * Represents a set of markup containing article/journey carousel objects, with corresponding and necessary navigation and linking logic.
 *
 * @param {object} props - The component props object.
 * @param {Array} [props.articles] - Optional array of article data objects for the carousel.
 * @param {Array} [props.journeys] - Optional array of journeys data objects for the carousel.
 * @param {Array} props.parentJourneySlug - The slug value for the parent journey.
 * @param {Array} [props.preload] - Optional array of preloaded articles or journeys data objects.
 * @param {string} props.theme - The theme value.
 * @param {string} props.title - The title value for the carousel.
 *
 * @returns {React.ReactElement} The Carousel component.
 */
const Carousel = ({
  articles,
  journeys,
  parentJourneySlug,
  preload,
  theme,
  title,
  ...passThroughProps
}) => {
  const { user } = useAuth();
  const itemsRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(false);
  const [data, setData] = useState(preload || passThroughProps?.data || []);
  const isBigCarousel = articles?.length;

  /**
   * Single-run convenience effect to fetch and set the data for the component.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!preload || passThroughProps?.data) {
        setData(await fetchCarousel({ articles, journeys }));
      } else {
        setData(preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handler function for mouse down event.
   *
   * Note: Ignore directive added to avoid the need for internal component state
   * management of elements and values. Tests cover basic user interactions with
   * mouse down/click, up, move, et al.
   *
   * @param {Event} event - The Event object associated with the mouse down action.
   */
  /* istanbul ignore next */
  function handleMouseDown(event) {
    setIsMouseDown(true);
    setStartX(event.pageX - itemsRef.current.offsetLeft);
    setScrollLeft(itemsRef.current.scrollLeft);
  }

  /**
   * Handler function for mouseleave event.
   *
   * Note: Ignore directive added to avoid the need for internal component state
   * management of elements and values. Tests cover basic user interactions with
   * mouse down/click, up, move, et al.
   */
  /* istanbul ignore next */
  function handleMouseLeave() {
    setIsMouseDown(false);
    setIsDragging(false);
  }

  /**
   * Handler function for mouse up event.
   *
   * Note: Ignore directive added to avoid the need for internal component state
   * management of elements and values. Tests cover basic user interactions with
   * mouse down/click, up, move, et al.
   */
  /* istanbul ignore next */
  function handleMouseUp() {
    setIsMouseDown(false);
    setIsDragging(false);
  }

  /**
   * Handler function for mouse move event.
   *
   * Note: Ignore directive added to avoid the need for internal component state
   * management of elements and values. Tests cover basic user interactions with
   * mouse down/click, up, move, et al. Also, the initial conditional check is a
   * convenience check for test coverage to ensure this function is properly
   * triggered as it doesn't rely on internal component state management.
   *
   * @param {Event} event - The Event object associated with the mouse move action.
   */
  /* istanbul ignore next */
  function handleMouseMove(event) {
    if (passThroughProps?.testOverride && passThroughProps?.onMouseMove) {
      passThroughProps.onMouseMove(event);
    }

    if (!isMouseDown) {
      return;
    }
    event.preventDefault();
    const x = event.pageX - itemsRef.current.offsetLeft;
    const distance = Math.abs(x - startX);
    const minDragDistance = 5;

    if (distance < minDragDistance) {
      setIsDragging(true);
    }

    const speed = (x - startX) * 1;
    itemsRef.current.scrollLeft = scrollLeft - speed;
  }

  /**
   * Convenience function to return a prefix for a call to action for an article/journey.
   *
   * @param {string} type - The article/journey type (Example: 'audio', 'text', 'video').
   *
   * @returns {string} The prefix for the call to action (Example: 'WATCH').
   */
  function getReadTxt(type) {
    let txt;
    switch (type) {
      case ICON_TYPES.audio:
        txt = 'LISTEN';
        break;
      case ICON_TYPES.video:
        txt = 'WATCH';
        break;
      default:
        txt = 'READ';
        break;
    }
    return txt;
  }

  /**
   * Convenience function to return a relative URL path for the specified article data.
   *
   * @param {object} article - The Article data object.
   *
   * @returns {string} The URL for the specified article.
   */
  function getArticleUrl(article) {
    return `/journeys/${parentJourneySlug}/${article['@name']}/`;
  }

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * Note: Ignore directive added to alleviate the need for all of the fallback
   * and default value logic. Sufficient coverage of user interaction and data
   * tracking is set up in the corresponding test file.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.component] - Optional value for component name.
   * @param {string} [params.component_url] - Optional value for component url.
   * @param {string} [params.event] - The Event object associated with the action..
   * @param {string} [params.label] - Optional value for label.
   */
  /* istanbul ignore next */
  function callAnalytics({ component, component_url, event, label }) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: component || 'Carousel',
        component_url:
          component_url || event?.currentTarget?.getAttribute('href'),
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleLinkClick = (event) => {
    callAnalytics({ component: 'Carousel Link', event });
  };

  /**
   * Handler function for Journeys card click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleJourneyCardClick = ({ component_url, event, label }) => {
    callAnalytics({
      component: 'Carousel Journey Card',
      component_url,
      event,
      label,
    });
  };

  return (
    <div
      className={`carousel-wrapper
      ${isBigCarousel ? 'big-cards' : 'small-cards'} ${theme}`.trim()}
      data-testid="lc-carousel-wrapper"
    >
      {!isBigCarousel || title ? (
        <div className="header-container">
          {title ? (
            <div>
              <h2 className="title" data-testid="lc-carousel-title">
                {title}
              </h2>
            </div>
          ) : null}
          {!isBigCarousel ? (
            <a
              className="all-button-container"
              href={'/journeys'}
              onClick={handleLinkClick}
            >
              <span className="text">All</span>
              <ArrowButton
                alt="Chevron Icon"
                className="icon"
                orientation="right"
                theme={theme}
              />
            </a>
          ) : null}
        </div>
      ) : null}
      <div
        className="contents"
        data-testid="lc-carousel-contents"
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={itemsRef}
      >
        {/* Note: Ignore directives added even though tests cover all cases, yet still show as uncovered. */}
        {data?.length
          ? data.map((item, i) =>
              isBigCarousel ? (
                <BigJourneyCard
                  author={item?.author}
                  buttonLabel={getReadTxt(item?.type)}
                  buttonLink={getArticleUrl(item)}
                  duration={item?.duration}
                  image={item?.featuredImage}
                  isDragging={isDragging}
                  key={`article-${item['@id']}`}
                  onClick={() => {
                    handleJourneyCardClick({
                      component_url: getArticleUrl(item),
                      label: item?.title,
                    });
                  }}
                  part={i + 1}
                  tags={item?.['mgnl:tags'] || []}
                  testOverride={passThroughProps?.testOverride}
                  title={item?.title}
                  type={item?.type}
                />
              ) : (
                <SmallJourneyCard
                  buttonLabel={'Start Journey'}
                  buttonLink={`/journeys/${item.slug || ''}`}
                  image={item?.image}
                  isDragging={isDragging}
                  key={i}
                  onClick={() => {
                    handleJourneyCardClick({
                      component_url: `/journeys/${item.slug || ''}`,
                      label: item?.title,
                    });
                  }}
                  part={item?.content?.length}
                  tags={item?.['mgnl:tags'] || []}
                  testOverride={passThroughProps?.testOverride}
                  title={item?.title}
                />
              ),
            )
          : null}
      </div>
    </div>
  );
};

export default Carousel;
