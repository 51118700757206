/**
 * @module Article
 */
import React from 'react';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { getQueryParam } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import { EditableArea } from '@magnolia/react-editor';
import { getAPIBase } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getAPIBase';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import {
  ACTIONS,
  EVENTS,
  FINISH_JOURNEY_URL,
  MGNL_ENV_VARS,
} from '../helpers/constants';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Modal from '../components/Modal/Modal';
import fetchBasicTemplate from '../helpers/dataFetchers/basicTemplateFetcher';
import ArticlePageHero from '../components/ArticlePageHero/ArticlePageHero';
import JourneyDetailsHero from '../components/JourneyDetailsHero/JourneyDetailsHero';
import Search from '../components/Search/Search';
import LCDevBar from '../components/LCDevBar';
import useTheme from '../hooks/useTheme';
import ArticleNavigation from '../components/ArticleNavigation/ArticleNavigation';
import './Article.scss';

const Article = (props) => {
  const {
    author,
    backgroundVideo,
    description,
    duration,
    featuredVideo,
    heroImage,
    isJourney = false,
    journeyName,
    journeyUrl,
    main,
    metadata,
    next,
    part,
    prev,
    title,
  } = props;
  const { user } = useAuth();
  const { storePageTheme } = useTheme();
  const [modal, setModal] = React.useState(null);
  const [data, setData] = React.useState(props?.preload);
  const [searchOpen, setSearchOpen] = React.useState(false);

  /**
   * Convenience effect to fetch and set modal and page data.
   */
  React.useEffect(() => {
    const fetchModalByHash = async () => {
      let { hash } = window.location;
      if (hash) {
        // eslint-disable-next-line prefer-destructuring
        hash = hash.split('#')[1];
        const apiBase = getAPIBase(MGNL_ENV_VARS);
        await fetch(`${apiBase}/.rest/delivery/modal?@name=${hash}`)
          .then((res) => res.json())
          .then((res) => {
            if (res?.results?.length) {
              setModal(res.results[0]);
            }
          })
          // eslint-disable-next-line no-console
          .catch((error) => Log.error(error));
      }
    };

    async function fetchData() {
      try {
        const theme = getQueryParam('theme');
        const basicTemplateData = await fetchBasicTemplate(props, theme);
        setData(basicTemplateData);
      } catch (error) {
        Log.error(error);
      }
    }
    if (props.preload) {
      setData(props.preload);
    } else {
      fetchData(); // NOSONAR
    }

    fetchModalByHash(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props?.backgroundColor) {
      storePageTheme(
        props.backgroundColor === 'cream' ? 'light-mode' : 'dark-mode',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchModalHandler = () => {
    setSearchOpen(!searchOpen);
  };

  const getFeaturedVideoModalData = () => {
    return {
      modalType: {
        field: 'videoModal',
        youtubeID: featuredVideo,
      },
    };
  };

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrides - Optional object of override values.
   */
  function callAnalytics(event, overrides = {}) {
    callSegmentTrack({
      event: overrides?.eventName || EVENTS.buttonAction,
      properties: {
        action: overrides?.action || ACTIONS.clicked,
        component: overrides?.component || 'Article',
        component_url:
          overrides?.component_url ||
          event?.currentTarget?.getAttribute('href'),
        label: overrides?.label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for navigation button link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {string} label - The label value of the button.
   */
  function handleNavigationButtonClick(event, label) {
    callAnalytics(event, { label });
  }

  return (
    <>
      {
        <div className="Basic">
          {data?.menuData ? (
            <Header
              menuData={data.menuData}
              menuItems={data.menuItems}
              navItems={data.navItems}
              searchModalHandler={searchModalHandler}
            />
          ) : null}
          <main
            className={
              props?.backgroundColor ? `bg-${props?.backgroundColor}` : 'gray'
            }
          >
            {searchOpen && <Search searchModalHandler={searchModalHandler} />}
            <article className={`${searchOpen ? 'hidden-content' : ''}`}>
              {isJourney ? (
                <JourneyDetailsHero
                  author={author}
                  buttonLabel="WATCH VIDEO"
                  buttonLink=""
                  description={description}
                  duration={duration}
                  gradientColor={props?.backgroundColor}
                  image={heroImage}
                  journeyName={journeyName}
                  journeyUrl={journeyUrl}
                  name={title}
                  next={next}
                  part={part}
                  prev={prev}
                  tags={metadata['mgnl:tags'] || []}
                  title={title}
                />
              ) : (
                <ArticlePageHero
                  author={author}
                  backgroundVideo={backgroundVideo}
                  buttonLabel="WATCH VIDEO"
                  buttonLink=""
                  description={description}
                  duration={duration}
                  gradientColor={props?.backgroundColor}
                  hasFeaturedVideo={Boolean(featuredVideo)}
                  image={heroImage}
                  name={title}
                  tags={metadata['mgnl:tags'] || []}
                  title={title}
                  toggleModal={() => setModal(true)}
                />
              )}

              {main ? (
                <EditableArea
                  className={`Area article-content-wrapper${
                    searchOpen ? 'hidden-content' : ''
                  }`}
                  content={main}
                />
              ) : null}
              {isJourney ? (
                <div className="bottom-navigation container">
                  <div className="content">
                    <ArticleNavigation
                      className="border-top"
                      nextText={next?.text}
                      nextUrl={
                        next?.url === journeyUrl
                          ? FINISH_JOURNEY_URL
                          : next?.url
                      }
                      onNextClick={(event) => {
                        handleNavigationButtonClick(
                          event,
                          next?.text || 'Next',
                        );
                      }}
                      onPrevClick={(event) => {
                        handleNavigationButtonClick(
                          event,
                          prev?.text || 'Back',
                        );
                      }}
                      prevText={prev?.text}
                      prevUrl={prev?.url}
                    />
                  </div>
                </div>
              ) : null}
            </article>
            {featuredVideo ? (
              <Modal
                data={getFeaturedVideoModalData()}
                setShowStatus={() => setModal(!modal)}
                showStatus={modal}
              />
            ) : null}
          </main>
          {data?.footerData ? <Footer footerData={data.footerData} /> : null}
          {process.env.NODE_ENV !== 'production' ? <LCDevBar /> : null}
        </div>
      }
    </>
  );
};

export default Article;
