/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module TopicsListing
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a simple text element with search results text containing a customizable term.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.customTerm] - The term used for the search results. Defaults to 'Result'. (Example: 42 Results found matching ’{search term}’.).
 * @param {Function} props.onClearResults - Handler function for the 'clear results' text click event.
 * @param {number} [props.resultCount] - The number of search results.
 * @param {string} [props.searchText] - The text used for the search.
 *
 * @returns {React.ReactElement} The SearchResultsText component.
 */
function SearchResultsText({
  customTerm = 'Result',
  onClearResults,
  resultCount = 0,
  searchText = '',
}) {
  const resultTerm = resultCount === 1 ? customTerm : `${customTerm}s`;
  return (
    <p className="search-result-text">
      <b>
        {resultCount} {resultTerm} found{' '}
      </b>
      matching ‘{searchText}’{' '}
      <span className="clear-result-text" onClick={onClearResults}>
        clear results
      </span>
    </p>
  );
}

export default SearchResultsText;
