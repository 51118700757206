/**
 * @module Tags
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS, HYPHENATED_TAGS } from '../../helpers/constants';
import './Tags.scss';

const Tags = ({ tags = [] }) => {
  const { user } = useAuth();

  /**
   * Handler function for tag click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleTagClick(event) {
    event.stopPropagation();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Tag',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Formats a given tag by replacing hyphens with spaces unless it is in a predefined list of hyphenated tags.
   *
   * @param {string} tag - The tag to format.
   *
   * @returns {string} - The formatted tag, with hyphens replaced by spaces, or the original tag if it is in the hyphenated tags list.
   */
  function formatTag(tag) {
    return HYPHENATED_TAGS.includes(tag) ? tag : tag?.replace(/-/g, ' ');
  }

  return (
    <div className="tag-wrapper" data-testid="lc-tags">
      {tags.map((t) => (
        <a
          className="tag"
          href={`/topics/${t}`}
          key={t}
          onClick={handleTagClick}
        >
          <span>{formatTag(t)}</span>
        </a>
      ))}
    </div>
  );
};

export default Tags;
