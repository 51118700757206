/**
 * @module GetAppliedTheme
 */
import { COLOR_THEMES } from './constants';

/**
 * Convenience function to return the theme to apply to the calling component based on the provided page and component themes.
 *
 * @param {string} pageTheme - The page theme value.
 * @param {string} componentTheme - Optional component theme value.
 *
 * @returns {string} The theme value to apply and use.
 */
export const getAppliedTheme = (pageTheme, componentTheme) => {
  let theme = COLOR_THEMES['dark-mode'];
  if (componentTheme) {
    if (componentTheme !== 'inherit' && componentTheme !== 'currentColor') {
      theme = componentTheme;
    } else {
      theme = pageTheme;
    }
  } else if (pageTheme) {
    theme = pageTheme;
  }
  return theme;
};
