/**
 * @module InstallApp
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './InstallApp.scss';
import appleStoreLogo from './app-store.svg';
import googlePlayStoreLogo from './googleplay-store copy.svg';

/**
 * Represents a view with call to action to install app.
 *
 * Note: This component is not needed/used, as not initially required. Leaving in place for future reference/use.
 *
 * @param {object} props - The component props object.
 * @param {string} props.title - The title value.
 *
 * @returns {React.ReactElement} The InstallApp component.
 */
const InstallApp = ({ title }) => {
  return (
    <div className="install-app-wrapper">
      <h2 className="install-app-title">{title}</h2>
      <div className="install-app-image-wrapper">
        <img alt="AppleStore" className="apple" src={appleStoreLogo} />
        <img
          alt="GooglePlayStore"
          className="google"
          src={googlePlayStoreLogo}
        />
      </div>
    </div>
  );
};

export default InstallApp;
