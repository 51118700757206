/**
 * @module FeaturedArticles
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import sample from 'lodash/sample';
import ArticleCard from '../ArticleCard/ArticleCard';
import ArrowButton from '../ArrowButton/ArrowButton';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import { fetchFeaturedArticles } from '../../helpers/dataFetchers/findsFetchers';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './FeaturedArticles.scss';

/**
 * Represents a component that fetches and displays selected featured articles.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.articles - The array of articles id's of which to fetch data.
 * @param {string} [props.background] - Optional background color value to apply as a `bg-` class name. If no value provided, it will default to a value of 'none'. (Example: 'gold' translates to applying `.bg-gold` as a class name value).
 * @param {string} [props.link] - Optional URL for the component's "All" link.
 * @param {Array} [props.preload] - Optional array containing preloaded articles.
 * @param {string} props.theme - The theme value for styling the card.
 * @param {string} props.title - The title value for the component.
 *
 * @returns {React.ReactElement} - The FeaturedArticles component.
 */
function FeaturedArticles({
  articles,
  background,
  link,
  preload,
  theme,
  title,
}) {
  const { user } = useAuth();
  const { pageTheme } = useTheme();
  const { isMobile } = useWindowSize();
  const [data, setData] = React.useState(preload);
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  const MAX_RESULTS = isMobile ? 3 : 6;

  /**
   * Handler function for link click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLinkClick(event) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Featured Articles',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Single-run convenience effect to fetch and set the data for the component.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!preload) {
        setData(await fetchFeaturedArticles({ articles }));
      } else {
        setData(preload);
      }
    }
    fetchData();
  }, [articles, preload]);

  const dataWithRandomTag = data?.map((article) => {
    const articleTags = article?.['mgnl:tags'];
    const randomTagArray = articleTags?.length ? [sample(articleTags)] : [];
    return {
      ...article,
      'mgnl:tags': randomTagArray,
    };
  });

  return (
    <div
      className={`featured-articles-wrapper ${appliedTheme} bg-${
        background || 'none'
      }`.trim()}
      data-testid="lc-featured-articles"
    >
      <div className="flex-content">
        {title ? <h2 className="title">{title}</h2> : null}
        {link ? (
          <a
            className="all-button-container"
            href={link}
            onClick={handleLinkClick}
          >
            <span className="text">All</span>
            <ArrowButton
              alt="Chevron Icon"
              className="icon"
              orientation="right"
              theme={appliedTheme}
            />
          </a>
        ) : null}
      </div>
      {dataWithRandomTag?.length ? (
        <div className="contents">
          {dataWithRandomTag.slice(0, MAX_RESULTS).map((article) => (
            <ArticleCard
              fetchedArticle={article}
              isSearchResult={true}
              key={article['jcr:uuid']}
              theme={appliedTheme}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default FeaturedArticles;
