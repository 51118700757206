/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @module Footer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { ACTIONS, EVENTS, SOCIAL_MEDIA_TYPES } from '../../helpers/constants';
import { brightEdgeAutoPilotScript } from '../../lib';
import EmbedItem from '../EmbedItem/EmbedItem';
import RichText from '../RichText/RichText';
import LinkRow from './components/LinkRow';
import SocialMediaLinks from './components/SocialMediaLinks';
import logo from './footer-logo.svg';
import './Footer.scss';

/**
 * Represents the footer area for the website.
 *
 * @param {object} props - The component props object.
 * @param {FooterData} props.footerData - Object of footer data elements.
 *
 * @returns {React.ReactElement} The Footer component.
 */
function Footer({ footerData }) {
  const { user } = useAuth();
  const { isDesktop } = useWindowSize();
  /* istanbul ignore next */
  const includeConsentManagerLink =
    process.env.OSANO_CONSENT_MANAGER_ACCOUNT_ID &&
    process.env.OSANO_CONSENT_MANAGER_CONFIG_ID &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER.toString() === 'true';
  /* istanbul ignore next */
  const includeBrightEdgeMarkup =
    process.env.BRIGHTEDGE_AUTOPILOT_CONFIG_ID &&
    process.env.BRIGHTEDGE_AUTOPILOT_INCLUDE &&
    process.env.BRIGHTEDGE_AUTOPILOT_INCLUDE.toString() === 'true';
  const now = new Date();
  const {
    embedForm,
    heading,
    pageNavLinks1,
    pageNavLinks2,
    pageNavLinks3,
    policyContent,
    socialMediaLinks,
  } = footerData || {};
  const linkRows = [pageNavLinks1, pageNavLinks2, pageNavLinks3];
  const [brightEdgeMarkup, setBrightEdgeMarkup] = React.useState(null);

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   * @param {object} overrideData - Optional object of data attributes to override those found on the Event object (Example: { component_url: 'https://customurl.com/', label: 'My Custom Label' }).
   */
  function handleElementClick(event, overrideData) {
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Footer',
        component_url:
          overrideData?.component_url ||
          event?.currentTarget?.getAttribute('href'),
        label: overrideData?.label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for Osano cookie preferences item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleOsanoConsentManagerClick(event) {
    event.preventDefault();
    event.stopPropagation();
    handleElementClick(event);
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  }

  /**
   * Handler function for social media link click event.
   *
   * Note: To make use of the single functional location for Segment analytics,
   * this function simply calls the shared handleElementClick() function, and
   * passes in overrideData since label and component_url may not be present
   * directly on the event data object.
   *
   * Also, the ignore directive is added since test coverage is handled
   * directly in an assertion specifically rendering SocialMediaLinks
   * rather than mocking the handler function here that is only defined
   * and used within this component and not specified as a prop.
   *
   * @param {object} params - The function params object.
   * @param {Event} params.event - The Event object associated with the click.
   * @param {object} params.socialItem - Object of social media item data.
   */
  function handleSocialMediaLinkClick({ event, socialItem }) {
    /* istanbul ignore next */
    handleElementClick(event, {
      component_url: socialItem?.socialMediaUrl,
      label:
        SOCIAL_MEDIA_TYPES[socialItem?.socialMediaType?.replace('icon-', '')],
    });
  }

  /**
   * Single-run convenience effect to set the markup for BrightEdge Autopilot.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (includeBrightEdgeMarkup) {
      setBrightEdgeMarkup(
        brightEdgeAutoPilotScript({
          configId: process.env.BRIGHTEDGE_AUTOPILOT_CONFIG_ID,
          placement: 'body',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer className="footer" data-testid="finds-footer">
      {footerData ? (
        <>
          {
            /* istanbul ignore next */ brightEdgeMarkup ? (
              <div className="footer-lc-be-wrapper">
                <div
                  className="lc-be-content"
                  dangerouslySetInnerHTML={{ __html: brightEdgeMarkup }}
                ></div>
              </div>
            ) : null
          }
          <div className="footer-wrapper container">
            <div className="footer-top">
              <img alt="Logo" className="logo" src={logo} />
              {isDesktop ? (
                <SocialMediaLinks
                  data-testid="social-links-desktop"
                  onLinkClick={handleSocialMediaLinkClick}
                  socialMediaLinks={socialMediaLinks}
                />
              ) : null}
            </div>
            <div className="footer-main">
              <div className="footer-left">
                <RichText
                  className="heading"
                  colorsetting={'text-yellow'}
                  content={heading}
                />
                {isDesktop ? (
                  <RichText
                    className="policy"
                    colorsetting={'text-white'}
                    content={policyContent}
                    data-testid="policy-content-text"
                  />
                ) : null}
              </div>
              <div className="footer-right">
                <div className="form">
                  <EmbedItem
                    classes={'no-background no-top-padding'}
                    type={embedForm}
                  />
                </div>
                <div className="page-nav-links">
                  {linkRows.map((row) => (
                    <LinkRow
                      key={`link-row-${row['@id']}`}
                      onLinkClick={handleElementClick}
                      row={row}
                    />
                  ))}
                </div>
              </div>
            </div>
            {!isDesktop ? (
              <SocialMediaLinks
                data-testid="social-links-mobile"
                onLinkClick={handleSocialMediaLinkClick}
                socialMediaLinks={socialMediaLinks}
              />
            ) : null}
          </div>
          <div className="footer-banner">
            <div className="container">
              <div>
                &copy; {now.getFullYear()} Life.Church. All rights reserved.
              </div>
              <div>
                Attend{' '}
                <a
                  href={implementUtmParams(
                    'https://live.life.church/',
                    window?.location,
                  )}
                  onClick={handleElementClick}
                >
                  Life.Church Online
                </a>
                <span> </span>or a{' '}
                <a
                  href={implementUtmParams(
                    'https://www.life.church/locations/',
                    window?.location,
                  )}
                  onClick={handleElementClick}
                >
                  Life.Church location
                </a>
                .
              </div>
              <div>
                <a
                  href={
                    'https://www.life.church/privacy/?utm_source=finds_life&utm_medium=referral&utm_campaign=snapshot-ad_no-dept_web&utm_content=footer_privacy'
                  }
                  onClick={handleElementClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                &nbsp;|{' '}
                <a
                  href={
                    'https://www.life.church/terms/?utm_source=finds_life&utm_medium=referral&utm_campaign=snapshot-ad_no-dept_web&utm_content=footer_terms'
                  }
                  onClick={handleElementClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Use
                </a>
                &nbsp;|{' '}
                <a
                  href={
                    'https://www.life.church/vulnerability-disclosure-program/?utm_source=finds_life&utm_medium=referral&utm_campaign=snapshot-ad_no-dept_web&utm_content=footer_vulnerability_disclosure_program'
                  }
                  onClick={handleElementClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  Vulnerability Disclosure Program
                </a>
                {includeConsentManagerLink ? (
                  <>
                    &nbsp;|{' '}
                    <a
                      href="#"
                      id="consent-manager-cookie-prefs"
                      onClick={handleOsanoConsentManagerClick}
                    >
                      Cookie Preferences
                    </a>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </footer>
  );
}

export default Footer;
