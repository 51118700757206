/* eslint-disable no-bitwise */
/* eslint-disable no-extend-native */
/**
 * @module AppHelpers
 */

String.prototype.hashCode = () => {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) {
    return hash.toString();
  }
  for (i = 0; i < this.length; i += 1) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
};
