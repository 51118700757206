/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module LCImage
 */
import React from 'react';
import Imgix from 'react-imgix';
import 'lazysizes';
import { replaceMagnoliaAssetsToImgix } from '@lifechurch/web-tools-io/dist/utils/helpers';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';

const ImgixWrapper = React.forwardRef((props1, ref) => {
  const { handleResize, ...restOfProps } = props1;
  const imgixClass = `lazyload ${convertValueToClassName(
    props1.className,
  )}`.trim();

  /*
    Wrapping the handleResize with the useEffect fixes the warning:
    Cannot update a component (`LCImage`) while rendering a different component (`ImgixWrapper`).
  */
  React.useEffect(() => {
    handleResize();
  }, [handleResize]);

  return (
    <div
      className={convertValueToClassName(props1.className)}
      data-testid="lc-image"
      ref={ref}
    >
      {props1.width ? (
        <Imgix
          {...restOfProps}
          className={imgixClass}
          htmlAttributes={props1.htmlAttributes}
          imgixParams={{ ...props1.imgixParams, fm: 'webp' }}
          sizes={props1.width.toString()}
          src={decodeURI(props1.src)}
          width={props1.width}
        />
      ) : null}{' '}
    </div>
  );
});

ImgixWrapper.displayName = 'ImgixWrapper';

const LCImage = (props) => {
  const { backup, htmlAttributes, imgixParams, src, parentRef } = props;
  const [imgixSrc, setImgixSrc] = React.useState('');
  const [width, setWidth] = React.useState('');
  const imgref = React.useRef(null);

  async function setImgixUrl() {
    let url = await replaceMagnoliaAssetsToImgix(
      decodeURI(src),
      process.env.IMGIX_BASE_URL,
    );
    if (backup && !url) {
      url = `${process.env.IMGIX_BASE_URL}/Media/${backup}.jpg`;
    }
    setImgixSrc(url);
  }

  function setWidthIfLarger(newWidth) {
    if (newWidth > width) {
      setWidth(newWidth);
    }
  }

  function handleResize() {
    const lcImageRef = parentRef ?? imgref;
    if (props.width) {
      setWidthIfLarger(props.width);
    } else if (lcImageRef?.current) {
      let w = lcImageRef?.current?.parent?.parent?.offsetWidth;
      if (lcImageRef.current?.offsetWidth) {
        w = lcImageRef?.current?.offsetWidth;
      } else if (lcImageRef.current?.parent?.offsetWidth) {
        w = lcImageRef?.current?.parent?.offsetWidth;
      }
      setWidthIfLarger(w);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useMemo(() => setImgixUrl(), [src]);

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <>
      {imgixSrc ? (
        <ImgixWrapper
          handleResize={handleResize}
          {...props}
          htmlAttributes={htmlAttributes}
          imgixParams={imgixParams}
          ref={imgref}
          src={decodeURI(imgixSrc)}
          width={Number(width)}
        />
      ) : null}
    </>
  );
};
export default LCImage;
