/**
 * @module JourneyListingHero
 */
import React from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import VideoWithParts from '../VideoWithParts/VideoWithParts';
import LCBackground from '../LCImage/LCBackground';
import { fetchJourneyListingHero } from '../../helpers/dataFetchers/findsFetchers';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './JourneyListingHero.scss';

/**
 * Represents a wrapper containing large background image overlayed with breadcrumb links, and journey-related content including and video content.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.featuredJourney] - The journey uuid value.
 * @param {object|string} [props.image] - Optional Journey image src URL.
 * @param {object} [props.preload] - Optional object of preloaded journey data.
 * @param {string} [props.theme] - The theme value.
 * @param {string} [props.title] - The Journey title.
 *
 * @returns {React.ReactElement} The JourneyListingHero component.
 */
const JourneyListingHero = ({
  featuredJourney = false,
  image,
  preload,
  theme,
  title,
}) => {
  const { pageTheme } = useTheme();
  const [data, setData] = React.useState(preload);
  const appliedTheme = getAppliedTheme(pageTheme, theme);

  /**
   * Single-run convenience effect to fetch and set the journey data.
   */
  React.useEffect(() => {
    async function fetchData() {
      if (!preload) {
        setData(await fetchJourneyListingHero({ featuredJourney }));
      } else {
        setData(preload);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`journey-listing-hero-wrapper ${appliedTheme}`}>
      <LCBackground
        className="background-wrapper"
        htmlAttributes={{
          'data-src': image?.['@link'] || image || '',
          'data-testid': 'lc-journey-listing-hero-bg-image',
        }}
        src={image?.['@link'] || image || ''}
      />
      <div className="background-gradient"></div>
      <Breadcrumbs initPageNames={['Journeys']} />
      <div className="content-wrapper container">
        <div className="content">
          {title ? <h1 className="title">{title}</h1> : null}
          {data ? (
            <div className="featured-journey">
              <h2 className="featured-heading">Featured Journey</h2>
              <VideoWithParts journeyData={data} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default JourneyListingHero;
