/**
 * @module FindsFetchers
 */
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from '../constants';

/**
 * Convenience function to retrieve an article by its unique uuid.
 *
 * @param {string} uuid - The article uuid.
 *
 * @returns {object} The fetched article data object, or a blank object if no corresponding article is found.
 */
async function fetchArticleByUUID(uuid) {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchArticleByUUID',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds-articles/v1/?jcr:uuid=${uuid}`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  /* istanbul ignore next */
  return res?.results?.length ? { ...res.results[0] } : {};
}

/**
 * Convenience function to retrieve a list of articles by their unique uuid values.
 *
 * @param {Array<string>} articleList - Array of article uuid values.
 *
 * @returns {Promise} The Promise object.
 */
async function fetchMultipleArticlesByUUID(articleList) {
  if (!articleList) {
    return [{}];
  }

  return Promise.all(
    articleList?.map((article) => fetchArticleByUUID(article)),
  );
}

/**
 * Convenience function to retrieve a journey by its unique uuid.
 *
 * @param {string} uuid - The article uuid.
 *
 * @returns {object} The fetched journey data object, or a blank object if no corresponding journey is found.
 */
async function fetchJourneyByUUID(uuid) {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchJourneyByUUID',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/journey/?jcr:uuid=${uuid}`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  return res?.results?.length ? { ...res.results[0] } : {};
}

/**
 * Convenience function to retrieve a list of journeys by their unique uuid values.
 *
 * @param {Array<string>} journeyList - Array of journey uuid values.
 *
 * @returns {Promise} The Promise object.
 */
async function fetchMultipleJourneysByUUID(journeyList) {
  return Promise.all(journeyList.map((journey) => fetchJourneyByUUID(journey)));
}

/**
 * Convenience function to retrieve the most recent journey.
 *
 * @returns {object} The fetched journey data object of the most recent journey.
 */
async function fetchLatestJourney() {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchLatestJourney',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/journey/?orderBy=startDate%20DESC`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  /* istanbul ignore next */
  return res?.results?.length ? { ...res.results[0] } : {};
}

/**
 * Convenience function to retrieve a journey by its unique uuid.
 *
 * @param {object} params - The function params object.
 * @param {string} params.journey - The journey uuid value.
 *
 * @returns {object} The fetched journey data object, or a blank object if no corresponding journey is found.
 */
export async function fetchJourneyPreview({ journey }) {
  return fetchJourneyByUUID(journey);
}

/**
 * Convenience function to retrieve a journey by its unique uuid (when the featuredJourney param is defined) or the most recent journey (when not defined).
 *
 * @param {object} params - The function params object.
 * @param {string} params.featuredJourney - Optional journey uuid value.
 *
 * @returns {object} The fetched journey data object, either specific for the provided featuredJourney uuid value, or the most recent journey.
 */
export async function fetchJourneyListingHero({ featuredJourney }) {
  if (featuredJourney) {
    return fetchJourneyByUUID(featuredJourney);
  }
  return fetchLatestJourney();
}

/**
 * Convenience function to retrieve the article for the specified uuid.
 *
 * @param {object} params - The function params object.
 * @param {string} params.article - The article uuid value.
 *
 * @returns {object} The fetched article data object.
 */
export async function fetchArticleCard({ article }) {
  return fetchArticleByUUID(article);
}

/**
 * Convenience function to retrieve a list of articles by their unique uuid values.
 *
 * @param {object} params - The function params object.
 * @param {Array<string>} params.articles - Array of article uuid values.
 *
 * @returns {Array<object>} Array of article data objects.
 */
export async function fetchFeaturedArticles({ articles }) {
  return fetchMultipleArticlesByUUID(articles);
}

/**
 * Convenience function to retrieve the featured article and a list of articles by their unique uuid values.
 *
 * @param {object} params - The function params object.
 * @param {Array<string>} params.articleList - Array of article uuid values.
 * @param {Array<string>} params.featuredArticle - Article uuid value for the featured article to retrieve with the hub preview.
 *
 * @returns {object} Data object containing `featured` object of featured article data, and `list` array of article data objects.
 */
export async function fetchHubPreview({ articleList, featuredArticle }) {
  const featured = await fetchArticleByUUID(featuredArticle);
  const list = await fetchMultipleArticlesByUUID(articleList);
  return { featured, list };
}

/**
 * Convenience function to retrieve articles and journeys for a carousel.
 *
 * @param {object} params - The function params object.
 * @param {Array} [params.articles] - Optional array of articles.
 * @param {Array<string>} [params.journeys] - Optional array of journey uuid values.
 *
 * @returns {Array} Array of article or journey data objects, or blank array if no params specified with valid length.
 */
export async function fetchCarousel({ articles, journeys } = {}) {
  if (articles?.length) {
    return articles;
  }
  if (journeys?.length) {
    return fetchMultipleJourneysByUUID(journeys);
  }
  return [];
}

/**
 * Convenience function to fetch the journey specified in the props object.
 *
 * @param {object} params - The function params object.
 * @param {string} params.journey - The journey uuid.
 *
 * @returns {object} Object of journey data.
 */
export async function fetchJourney({ journey }) {
  return fetchJourneyByUUID(journey);
}

/**
 * Convenience function to retrieve social media links.
 *
 * @returns {object} Data object of social media links.
 */
export async function fetchSocials() {
  const res = await getMagnoliaItem({
    caller: 'src/helpers/dataFetchers/findsFetchers.js > fetchSocials',
    mgnlEnvVars: MGNL_ENV_VARS,
    path: `/.rest/delivery/finds/share/`,
    workspaceMap: ENDPOINT_WORKSPACE_MAP,
  }); // NOSONAR
  const socials = res?.results?.length ? { ...res.results[0] } : {};
  return socials?.socialMediaLinks || {};
}
