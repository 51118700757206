/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module JourneysListing
 */
import React from 'react';
import {
  Configure,
  InstantSearch,
  useHits,
  useInstantSearch,
  useSearchBox,
} from 'react-instantsearch';
import Slider from 'react-slick';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import FilterSelect from '../FilterSelect/FilterSelect';
import ImageWithParts from '../ImageWithParts/ImageWithParts';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import ArrowButton from '../ArrowButton/ArrowButton';
import SearchResultsText from '../SearchResultsText/SearchResultsText';
import SearchTagFilters from '../SearchTagFilters/SearchTagFilters';
import {
  ACTIONS,
  EVENTS,
  JOURNEYS_INDEX,
  JOURNEYS_SORT_ITEMS,
  ZERO_RESULTS_TEXT,
} from '../../helpers/constants';
import { getAppliedTheme } from '../../helpers/getAppliedTheme';
import useTheme from '../../hooks/useTheme';
import './JourneysListing.scss';

/**
 * Convenience function to trigger callSegmentTrack.
 *
 * @param {object} params - The function params object.
 * @param {string} [params.action] - Optional value for action.
 * @param {string} [params.componentName] - Optional name of the component.
 * @param {string} [params.event] - Optional event associated with the tracking request.
 * @param {string} [params.label] - Optional value for label.
 * @param {object} [params.user] - Optional object containing the user data.
 * @param {string} [params.value] - Optional value associated with the tracking request.
 */
function callAnalytics({
  action,
  componentName = 'Journeys Listing',
  event,
  label,
  user,
  value,
}) {
  callSegmentTrack({
    event: event || EVENTS.buttonAction,
    properties: {
      action: action || ACTIONS.clicked,
      component: componentName,
      component_url: null,
      label,
      logged_in: !!user,
      preferred_campus: null,
      referrer: document?.referrer || null,
      title: document?.title || '',
      url: window?.location?.href,
      user_id: user?.['https://www.life.church/rock_person_alias_id'],
      value,
    },
  });
}

/**
 * Wrapper component for SearchResultsText that accesses Algolia InstantSearch hits.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.handleReset - Function to reset the search.
 * @param {string} props.searchInput - The current search input text.
 *
 * @returns {React.ReactElement} - The SearchResultsText populated with aggregated hit count and search text.
 */
function ResultsText({ handleReset, searchInput }) {
  const { hits } = useHits();
  return (
    <SearchResultsText
      customTerm="Journey"
      onClearResults={handleReset}
      resultCount={hits?.length || 0}
      searchText={searchInput}
    />
  );
}

/**
 * Component that displays a single journey hit.
 *
 * @param {object} props - The component props object.
 * @param {JourneyItem} props.hit - The journey hit data to be displayed.
 *
 * @returns {React.ReactElement} - The JourneyHit component.
 */
function JourneyHit({ hit }) {
  const journey = hit?.data;
  if (journey) {
    journey['mgnl:tags'] = journey.topics || journey['mgnl:tags'];
  }
  return (
    <ImageWithParts
      algoliaFields={{
        index: JOURNEYS_INDEX,
        position: hit.__position,
        productID: hit.objectID,
        queryID: hit.__queryID,
      }}
      isListing={true}
      journeyData={journey}
      key={hit?.data?.title}
    />
  );
}

/**
 * Component that renders a grid of JourneyHit components.
 *
 * @param {object} props - The component props object.
 * @param {Array<JourneyItem>} props.hits - An array of hit objects to be rendered as JourneyHit components.
 *
 * @returns {React.ReactElement|null} The rendered GridSlide component if hits are present, otherwise null.
 */
function GridSlide({ hits }) {
  return hits?.length ? (
    <div className="grid-slide contents">
      {hits.map((hit) => (
        <JourneyHit hit={hit} key={hit.objectID} />
      ))}
    </div>
  ) : null;
}

/**
 * Transforms the journey items returned by the Algolia search.
 *
 * @param {Array<JourneyItem>} items - The original array of journey items returned from the Algolia search.
 *
 * @returns {Array<JourneyItem>} The transformed array of journey items, limited to a maximum of 50 results.
 */
const transformJourneysItems = (items) => {
  const MAX_RESULTS = 50;
  if (!items?.length) {
    return items;
  }
  return items.slice(0, MAX_RESULTS);
};

/**
 * Custom component that displays Algolia Journeys search hits in a slider format.
 *
 * @param {object} props - The component props object.
 * @param {string} props.appliedTheme - The theme applied to style the component.
 * @param {string} [props.parentComponentName] - Optional name of the parent component.
 * @param {string} [props.title] - Optional title of the section.
 * @param {Function} [props.transformJourneys] - Optional function to transform the journeys hits before displaying them.
 *
 * @returns {React.ReactElement} - The JourneysHitsSlider component.
 */
export function JourneysHitsSlider({
  appliedTheme,
  parentComponentName,
  title,
  transformJourneys,
}) {
  // Default implementation: just return the items as is.
  const defaultTransformJourneys = (items) => items;

  const transformItems = React.useCallback(
    (items) => (transformJourneys || defaultTransformJourneys)(items),
    [transformJourneys],
  );

  const { results } = useInstantSearch();
  const { query } = useSearchBox();
  const { hits } = useHits({ transformItems });
  const searchClient = useAlgoliaClient();
  const { isMobile } = useWindowSize();
  const [currentSlide, setCurrentSlide] = React.useState(1);
  let sliderRef = React.useRef(null);
  const sectionTopRef = React.useRef(null);
  const hitsPerSlide = isMobile ? 2 : 4;
  const totalSlides = Math.ceil(hits.length / hitsPerSlide);
  const [lastThree, setLastThree] = React.useState([]);
  const { user } = useAuth();

  const settings = {
    accessibility: false,
    arrows: false,
    beforeChange: (current, next) => {
      const value = next >= 0 ? next + 1 : 1;
      setCurrentSlide(value);
    },
    dots: false,
    infinite: false,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const groupedHits = hits?.reduce((acc, hit, index) => {
    const slideIndex = Math.floor(index / hitsPerSlide);
    if (!acc[slideIndex]) {
      acc[slideIndex] = [];
    }
    acc[slideIndex].push(hit);
    return acc;
  }, []);

  /**
   * Handler function to scroll the page to the section referenced by sectionTopRef.
   */
  const scrollToSection = () => {
    if (sectionTopRef.current) {
      sectionTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  /**
   * Handler function for Back button click.
   */
  function handleBackClick() {
    callAnalytics({ componentName: parentComponentName, label: 'Back', user });
    sliderRef.slickPrev();
    setTimeout(() => {
      scrollToSection();
    }, 300);
  }

  /**
   * Handler function for Next button click.
   */
  function handleNextClick() {
    callAnalytics({ componentName: parentComponentName, label: 'Next', user });
    sliderRef.slickNext();
    setTimeout(() => {
      scrollToSection();
    }, 300);
  }

  /**
   * Convenience effect to fetch the last three journeys from the Algolia search index on component mount.
   */
  React.useEffect(() => {
    searchClient
      .search([
        {
          clickAnalytics: true,
          indexName: JOURNEYS_INDEX,
          params: {
            hitsPerPage: 3,
          },
        },
      ])
      .then((result) => {
        const threeHitsResults = result?.results?.[0];
        const hitsWithMissingFields = threeHitsResults?.hits?.map(
          (hit, index) => ({
            ...hit,
            __position: index + 1,
            __queryID: threeHitsResults?.queryID,
          }),
        );
        setLastThree(hitsWithMissingFields);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (results.nbHits === 0 && query && lastThree?.length) {
    return (
      <div className="no-results-wrapper">
        {title ? <h2 className="listing-title">{title}</h2> : null}
        <p>{ZERO_RESULTS_TEXT}</p>
        <GridSlide hits={lastThree} />
      </div>
    );
  }

  if (!hits?.length) {
    return null;
  }

  return (
    <div className="slider-container" ref={sectionTopRef}>
      {title ? <h2 className="listing-title">{title}</h2> : null}
      <Slider
        {...settings}
        draggable={totalSlides > 1}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        {groupedHits.map((slideHits, index) => (
          <GridSlide hits={slideHits} key={`slide-hit-${index}`} />
        ))}
      </Slider>
      {hits.length ? (
        <div className="pagination">
          <ArrowButton
            alt="Back Icon"
            className="icon"
            onClick={handleBackClick}
            orientation="left"
            theme={appliedTheme}
          />
          <div className="counter">
            {currentSlide ?? 1} / {totalSlides}
          </div>
          <ArrowButton
            alt="Next Icon"
            className="icon"
            onClick={handleNextClick}
            orientation="right"
            theme={appliedTheme}
          />
        </div>
      ) : null}
    </div>
  );
}

/**
 * Represents a wrapper containing Journey search, filters, and listing elements.
 *
 * @param {object} props - The component props object.
 * @param {object} [props.metadata] - Data object of journey/article metadata.
 * @param {string} props.theme - The theme value.
 *
 * @returns {React.ReactElement} - The JourneysListing component.
 */
const JourneysListing = ({ metadata, theme }) => {
  const { user } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const { pageTheme } = useTheme();
  const appliedTheme = getAppliedTheme(pageTheme, theme);
  const searchClient = useAlgoliaClient();
  const [searchInput, setSearchInput] = React.useState('');
  const childRef = React.useRef(null);
  const tagFilters = metadata?.['mgnl:tags'] || [];
  const getActiveTag = (tag) => (tagFilters.includes(tag) ? tag : '');
  const [filter, setFilter] = React.useState(
    getActiveTag(urlParams.get('tag')?.toLowerCase()),
  );
  const [showResults, setShowResults] = React.useState(false);

  const setActiveTag = (tag) => {
    if (getActiveTag(tag)) {
      setFilter(tag !== filter ? tag : '');
    }
  };

  /**
   * Handler function for Tag click.
   *
   * @param {string} filterItem - The filter value.
   */
  function handleTagClick(filterItem) {
    callAnalytics({ label: filterItem, user });
    setActiveTag(filterItem);
  }

  /**
   * Handler function for reset event.
   */
  function handleReset() {
    callAnalytics({ label: 'Reset', user });
    if (childRef.current) {
      childRef.current.handleReset();
    }
  }

  /**
   * Convenience effect to control the display of search results.
   * This effect introduces a small delay before updating the state that controls the display of results text, and helps to:
   * 1. Avoid rapid UI that could cause flickering.
   * 2. Reduce the number of re-renders when the user is typing quickly.
   * 3. Fix the issue: "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.".
   */
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowResults(searchInput.length > 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [searchInput]);

  return (
    <div className={`journeys-listing-wrapper ${appliedTheme}`}>
      <InstantSearch indexName={JOURNEYS_INDEX} searchClient={searchClient}>
        <Configure
          clickAnalytics={true}
          filters={filter ? `data.topics:${filter}` : ''}
        />
        <div className="filters">
          <div className="left-filters">
            <div className="search-input-wrapper">
              <FindsSearchBox
                ref={childRef}
                setSearchInput={setSearchInput}
                theme={appliedTheme}
              />
            </div>
            <FilterSelect items={JOURNEYS_SORT_ITEMS} theme={appliedTheme} />
          </div>
          <SearchTagFilters
            activeFilter={filter}
            handleTagClick={handleTagClick}
            tagFilters={tagFilters}
          />
        </div>
        {showResults ? (
          <ResultsText handleReset={handleReset} searchInput={searchInput} />
        ) : null}
        <JourneysHitsSlider
          appliedTheme={appliedTheme}
          transformJourneys={transformJourneysItems}
        />
      </InstantSearch>
    </div>
  );
};

export default JourneysListing;
